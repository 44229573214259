import React from 'react';

export const authRouteConfig = [
  {
    routes: [
      {
        path: '/signin',
        component: React.lazy(() => import('./Signin')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/signup',
        component: React.lazy(() => import('./Signup')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/signup-company',
        component: React.lazy(() => import('./SignupCompany/components/Main/CompanyForm')),
      },
      
    ],
  },
  {
    routes: [
      {
        path: '/invite-company-user',
        component: React.lazy(() => import('./InviteCompanyUser')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/forget-password',
        component: React.lazy(() => import('./ForgetPassword')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/error-pages/error-404',
        component: React.lazy(() => import('../errorPages/Error404/index')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/confirm-company-registration',
        component: React.lazy(() => import('./CompanyRegistration/CompanyRegistration')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/first-user-registartion',
        component: React.lazy(() => import('./CompanyRegistration/FirstUserSignup')),
      },
    ],
  },
];


