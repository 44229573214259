import {useMediaQuery} from '@material-ui/core';
import {createTheme, useTheme} from '@material-ui/core/styles';
import {Breakpoint} from '@material-ui/core/styles/createBreakpoints';
import moment from 'moment';
import {useIntl} from 'react-intl';
import {CremaTheme} from '../../types/AppContextPropsType';
import Chance from 'chance';
type BreakpointOrNull = Breakpoint | null;

export const isBreakPointDown = (key: Breakpoint) => {
  const defaultTheme = createTheme();
  return defaultTheme.breakpoints.values[key];
};

export const useDownBreakPointChecker = (key: Breakpoint) => {
  return useMediaQuery((theme: CremaTheme) => theme.breakpoints.down(key));
};

export const useBreakPointDown = (key: Breakpoint) => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down(key));
};

export const useWidth = () => {
  const theme: CremaTheme = useTheme();
  const keys: Breakpoint[] = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output: BreakpointOrNull, key: Breakpoint) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) ?? 'xs'
  );
};

export const createRoutes = (routeConfigs: any[]) => {
  let allRoutes: any[] = [];
  routeConfigs.forEach((config) => {
    allRoutes = [...allRoutes, ...setRoutes(config)];
  });
  return allRoutes;
};

export const setRoutes = (config: any) => {
  let routes = [...config.routes];
  if (config.auth) {
    routes = routes.map((route) => {
      let auth = route.auth
        ? [...config.auth, ...route.auth]
        : [...config.auth];
      return {...route, auth};
    });
  }

  return [...routes];
};
export const getBreakPointsValue = (valueSet: any, breakpoint: string) => {
  if (typeof valueSet === 'number') return valueSet;
  switch (breakpoint) {
    case 'xs':
      return valueSet.xs;
    case 'sm':
      return valueSet.sm || valueSet.xs;
    case 'md':
      return valueSet.md || valueSet.sm || valueSet.xs;
    case 'lg':
      return valueSet.lg || valueSet.md || valueSet.sm || valueSet.xs;
    default:
      return (
        valueSet.xl || valueSet.lg || valueSet.md || valueSet.sm || valueSet.xs
      );
  }
};
export const getFileSize = (bytes: number) => {
  if (bytes === 0) return '0 Bytes';
  let k = 1024,
    dm = 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const getCustomDateTime = (
  value = 0,
  unit = 'days',
  format = 'YYYY-MM-DD',
): string => {
  if (value === 0) {
    return moment().format(format);
  } else {
    // @ts-ignore
    return moment().add(value, unit).format(format);
  }
};

export const formatDateString = (
  date: string | number,
  format = 'YYYY-MM-DD hh:mm',
): string => {
  return moment(new Date(date)).format(format);
};

export const timeFromNow = (date: string) => {
  const timestamp = +moment(date).format('X');
  const newDate = moment.unix(timestamp);
  return moment(newDate).fromNow();
};

// 'intl' service singleton reference
let intl: any;

export function IntlGlobalProvider({children}: any) {
  intl = useIntl();
  // Keep the 'intl' service reference
  return children;
}

export const appIntl = () => {
  return intl;
};
export const checkPermission = (
  routeAuth: any | null | undefined,
  userRole: any | null | undefined,
) => {
  if (routeAuth === null || routeAuth === undefined) {
    return true;
  }

  if (userRole && Array.isArray(userRole)) {
    return routeAuth.some((r: any) => userRole.indexOf(r) >= 0);
  }

  if (routeAuth.length === 0) {
    return !userRole || userRole.length === 0;
  }
  if (userRole && Array.isArray(userRole) && Array.isArray(routeAuth)) {
    return routeAuth.some((r) => userRole.indexOf(r) >= 0);
  }
  return routeAuth.indexOf(userRole) >= 0;
};

export const GetCurrencyType = (currency: any) => {
  let symbol = '$';

  if (currency == 'GBP') {
    symbol = '£';
  }
  return symbol;
};

export const GetCurrencyTypeSymbol = (Amount: string | number) => {
  let symbol = '$';

  const arr = Amount?.split(' ');

  const type = arr?.[1];

  if (type === 'GBP') {
    symbol = '£';
  }

  return symbol;
};

export const GetCurrencyTypeInWords = (Amount: string | number) => {
  const arr = Amount?.split(' ');

  const type = arr?.[1];

  return type;
};

export const GetCurrencyTypeInWordsForCurrencyFirst = (
  Amount: string | number,
) => {
  const arr = Amount?.split(' ');

  const type = arr?.[0];

  return type;
};

export const GetAmount = (Amount: string | number) => {
  let price = 0;

  if (typeof Amount != 'string') {
    price = Amount;
    return price;
  } else {
    const arr = Amount?.split(' ');

    const price = arr?.[0];

    return parseFloat(price);
  }
};

export const GetAmountForCurrencyFirst = (Amount: string | number) => {
  let price = 0;

  if (typeof Amount != 'string') {
    price = Amount;
    return price;
  } else {
    const arr = Amount?.split(' ');

    const price = arr?.[1];

    return parseFloat(price);
  }
};

export const GetIdFromArray = (MainParent: []) => {
  let IdList: number[] = [];

  if (MainParent?.length > 0) {
    MainParent.forEach((val, idx) => IdList.push(val?.id));
  }

  return IdList;
};

export const AWS_BUCKET_KEYS = {
  AWS_ACCESS_KEY_ID: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  AWS_SECRET_ACCESS_KEY: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  AWS_S3_BUCKET_NAME: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
  REGION: process.env.REACT_APP_AWS_REGION,
};

export const generateUniqeId = () => {
  const chance = new Chance();

  let UniqueId = chance.integer({min: 0, max: 255});
  return UniqueId;
};


export  const AllowedFormats = {

  movie :[
    'video/mp4',
    'video/webm',
    'video/ogg',
    'video/ogg; codecs="theora, vorbis"',
    'video/x-msvideo',
    'video/quicktime',
    'video/x-ms-wmv',
    'video/x-flv',
    'video/x-matroska'
    // Add more formats as needed
  ],
  voiceover:[
    'video/mp4',
    'video/mp3',
    'video/webm',
    'video/ogg',
    'video/ogg; codecs="theora, vorbis"',
    'video/x-msvideo',
    'video/quicktime',
    'video/x-ms-wmv',
    'video/x-flv',
    'video/x-matroska'
    // Add more formats as needed
  ],
  floorplan:['application/pdf','image/jpeg', 'image/png',],
  photography:['image/jpeg','image/RAF','image/NEF', 'image/png', 'image/gif','image/svg+xml','image/ARW','image/CR2','image/CR3'],
  
} 

export const BILLING_USER_TYPES = ['Administrator', 'Accountant'];
export  const validateFileType = (file:any,AllowedFileFormat:[]) => {
    return AllowedFileFormat.includes(file.type);
};
