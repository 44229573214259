import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {PlaceNewOrder} from 'redux/actions/NewOrder';
import {AppState} from 'redux/store';
import {INewOrderPayload} from './INewOrderPayload';
import getPlaceOrderApiPayload from './OrderFunctions';
import useActiveButton from './hooks/useActiveButton';
import {ADMIN_USER_TYPES, GetSteps} from './staticData';
import moment from 'moment';
export const useNewOrder = () => {
  const dispatch = useDispatch();
  const {user} = useSelector<AppState, AppState['auth']>(({auth}) => auth);

  const dateTemp = moment();
  const [appliedPoints, setAppliedPoints] = useState();
  const [payload, setPayload] = useState<INewOrderPayload>({
    clientDetails: {
      adminCountryCode: user?.user?.country,
    },
    locationAndTimeDetails: {
      country: user?.user?.country?.toString(),
      contactMe: false,
      contactMeDate: dateTemp,
      contactMeTime: '',
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
    },
   
    upgradePackageDetails: false,
    key_pick_up_locations: {
      is_key_pick_up_enable: false,
    },
    key_pick_up_type: 'nokeypickup',
  });

  const {
    recommendedProductsLoading,
    recommendedProducts,
    PlaceOrderLoading,
    placeorderData,
    addons,
  } = useSelector<AppState, AppState['newOrder']>(({newOrder}) => newOrder);
  const [activeStep, setActiveStep] = useState(
    ADMIN_USER_TYPES.includes(user?.user_type || '') ? 0 : 1,
  );

  const [showAddonSelection, setShowAddonSelection] = useState(false);
  const steps = GetSteps(user?.user_type);

  useEffect(() => {
    if (activeStep === 1 && !payload.clientDetails?.userId && user) {
      setPayload((prevVal) => ({
        ...prevVal,
        clientDetails: {
          ...prevVal.clientDetails,
          userId: user?.user.id,
          userDetails: user,
        },
        locationAndTimeDetails: {
          ...prevVal.locationAndTimeDetails,
          country: user?.user?.country?.toString(),
        },
      }));
    }
  }, [activeStep, payload.clientDetails?.userId, user]);

  useEffect(() => {
    setPayload((prevVal) => ({
      ...prevVal,
      addonsDetails: undefined,
    }));
  }, [payload.recommendedProductDetails?.id]);

  const onSkipRecommended = () => {
    setPayload((prevVal) => ({
      ...prevVal,
      recommendedProductDetails: undefined,
    }));

    setActiveStep(2);
  };

  const onSkipUpgraded = () => {
    setPayload((prevVal) => ({
      ...prevVal,
      upgradedPackageId: '',
    }));

    onNextStep();
  };

  const goToStep = (step: number) => {
    setActiveStep(step);
  };

  const onBackStep = () => {
    if (activeStep !== 0) {
      if (payload?.recommendedProductDetails && activeStep === 3) {
        return setActiveStep((prevVal) => prevVal - 2);
      } else {
        return setActiveStep((prevVal) => prevVal - 1);
      }
    }
    if (payload?.recommendedProductDetails?.id) {
      return setActiveStep((prevVal) => prevVal + 2);
    } else {
      return setActiveStep((prevVal) => prevVal + 1);
    }
  };

  const onNextStep = () => {
    if (payload?.recommendedProductDetails && activeStep === 1) {
      return setActiveStep((prevVal) => prevVal + 2);
    } else {
      return setActiveStep((prevVal) => prevVal + 1);
    }
  };

  const PlaceOrder = () => {
    const APIBODY = getPlaceOrderApiPayload(payload, appliedPoints);

    dispatch(PlaceNewOrder(APIBODY, user));
  };

  const onBackClick = () => {
    setShowAddonSelection(false);
  };
  const {buttons, AddonButtons} = useActiveButton({
    onBackStep,
    activeStep,
    user_type: user?.user_type,
    onSkipRecommended,
    onSkipUpgraded,
    payload,
    onNextStep,
    onBackClick,
    PlaceOrder,
  });

  const getRecommendedPackagePrice = (
    last_order_ref_id: string | undefined,
  ) => {
    let Price: number | undefined = 0;

    recommendedProducts.last_orders?.forEach((_) => {
      if (_.last_order_ref_id?.toString() === last_order_ref_id) {
        Price = _.price;
      }
    });

    return Price;
  };


  function extractIntegers(inputString) {
    // var numbersOnly = inputString.replace(/\D/g, ''); // Remove non-digit characters
    // var integerOnly = /^\d+$/.test(numbersOnly) ? numbersOnly : ''; // Check for integer
    
    // return integerOnly;

    if(inputString!=""){
      let extractedNumber = inputString.replace(/\D/g, '').match(/^\d{11}/);


      if(extractedNumber?.[0]){
        let number = extractedNumber[0]
  
        var numbersOnly = number.replace(/\D/g, ''); // Remove non-digit characters
      var integerOnly = /^\d+$/.test(numbersOnly) ? numbersOnly : ''; // Check for integer
      
      return integerOnly;
      }else{
        return inputString
      }
    }
  else{
    return ""
  }
     
  }

  useEffect(() => {
  if(activeStep===4){
    let selectedUser = payload.clientDetails;
  

    console.log("selectedUser",selectedUser?.userDetails?.user?.country)
    if(payload.contactDetails?.stateChanged){
      setPayload((prev) => ({
        ...prev,
        
      }))
    }else{
      if(selectedUser?.userDetails?.user?.country ===2){
        setPayload((prev) => ({
          ...prev,
          contactDetails: {
            firstName: selectedUser?.userDetails?.user?.first_name ?? '',
            lastName: selectedUser?.userDetails?.user?.last_name ?? '',
            email: selectedUser?.userDetails?.user?.email ?? '',
            mobile: extractIntegers(selectedUser?.userDetails?.mobile)   ?? '',
            telephone: extractIntegers(selectedUser?.userDetails?.phone)  ?? '',
          },  
        }))
      }else{
        setPayload((prev) => ({
          ...prev,
          contactDetails: {
            firstName: selectedUser?.userDetails?.user?.first_name ?? '',
            lastName: selectedUser?.userDetails?.user?.last_name ?? '',
            email: selectedUser?.userDetails?.user?.email ?? '',
            mobile: extractIntegers(selectedUser?.userDetails?.mobile)   ?? '',
            telephone: extractIntegers(selectedUser?.userDetails?.phone)  ?? '',
          },  
        }))
      }
    
    }
    
  }
  },[activeStep])
  
  return {
    payload,
    user,
    buttons,
    steps,
    AddonButtons,
    activeStep,
    showAddonSelection,
    setShowAddonSelection,
    goToStep,
    getRecommendedPackagePrice,
    setPayload,
    PlaceOrderLoading,
    setActiveStep,
    appliedPoints,
    setAppliedPoints
  };
};

export default useNewOrder;
