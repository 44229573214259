import jwtAxios from '@crema/services/auth/jwt-auth/jwt-api';
import {
  FETCH_ADDONS_REQUEST,
  FETCH_ERROR_ADDONS,
  FETCH_ERROR_COMPANY_USERS,
  FETCH_ERROR_GET_RECOMMENDED_PRODUCTS,
  FETCH_ERROR_GET_UPGRADED_PRODUCTS,
  FETCH_ERROR_PRODUCT_CATEGORIES,
  FETCH_ERROR_SUB_PRODUCT_TYPES,
  FETCH_REQUEST_ERROR_ADDONS,
  FETCH_REQUEST_SUCCESS_ADDONS,
  FETCH_START_ADDONS,
  FETCH_START_COMPANY_USERS,
  FETCH_START_GET_RECOMMENDED_PRODUCTS,
  FETCH_START_GET_UPGRADED_PRODUCTS,
  FETCH_START_PRODUCT_CATEGORIES,
  FETCH_START_SUB_PRODUCT_TYPES,
  FETCH_SUCCESS_ADDONS,
  FETCH_SUCCESS_COMPANY_USERS,
  FETCH_SUCCESS_GET_RECOMMENDED_PRODUCTS,
  FETCH_SUCCESS_GET_UPGRADED_PRODUCTS,
  FETCH_SUCCESS_PRODUCT_CATEGORIES,
  FETCH_SUCCESS_SUB_PRODUCT_TYPES,
  POST_NEW_ORDER_FAIL,
  POST_NEW_ORDER_REQUEST,
  POST_NEW_ORDER_REQUEST_SUCCESS,
  GET_AVAILABLE_POINTS,
  GET_AVAILABLE_POINTS_SUCCESS,
  GET_AVAILABLE_POINTS_ERROR
} from '../../types/actions/NewOrder.action';
import {fetchError, showMessage} from '../actions/Common';
export const getCompanyUsers = ({
  companyId,
  country_code,
}: {
  companyId: string | number | null;
  country_code?: number;
}) => {
  return async (dispatch: any) => {
    dispatch({
      type: FETCH_START_COMPANY_USERS,
    });
    try {
      let companyUsers;
      let companyDecedent;
      if (companyId) {
        companyUsers = await jwtAxios.get(
          `organisations/company-users/?id=${companyId}`,
        );
      } else {
        companyDecedent = await jwtAxios.get(
          `organisations/company-descendants/?country_code=${country_code}`,
        );
      }

      dispatch({
        type: FETCH_SUCCESS_COMPANY_USERS,
        payload: {
          agents: companyUsers ? companyUsers.data.result : [],
          companies: companyDecedent ? companyDecedent.data.result : [],
        },
      });
    } catch (err) {
      dispatch({type: FETCH_ERROR_COMPANY_USERS});
    }
  };
};

export const getUserDetails = ({
  user_id,
  cb,
}: {
  user_id: number;
  cb?: (res: any) => void;
}) => {
  return async (dispatch: any) => {
    try {
      const res = await jwtAxios.get(
        `accounts/user-details/?user_id=${user_id}`,
      );

      if (res.data.result && cb) {
        cb(res.data.result);
      }
    } catch (err) {}
  };
};

export const getAvailablePoints = ({
  user_id
} : any) => {
      return async (dispatch: any) => {
        dispatch({
          type: GET_AVAILABLE_POINTS,
        });
        try {
          const productsL1 = await jwtAxios.get(
            `orders/get-active-credits/?user_id=${user_id}`,
          );
          dispatch({
            type: GET_AVAILABLE_POINTS_SUCCESS,
            payload: productsL1.data.result,
          });
        } catch (err) {
          dispatch({type: GET_AVAILABLE_POINTS_ERROR});
        }
  };
};

export const getRecommendedProducts = ({
  user_id,
}: {
  user_id: number | string;
}) => {
  return async (dispatch: any) => {
    dispatch({
      type: FETCH_START_GET_RECOMMENDED_PRODUCTS,
    });
    try {
      const addons = await jwtAxios.get(
        `products/recommended-package/?user_id=${user_id}`,
      );
      dispatch({
        type: FETCH_SUCCESS_GET_RECOMMENDED_PRODUCTS,
        payload: addons.data.result,
      });
    } catch (err) {
      dispatch({type: FETCH_ERROR_GET_RECOMMENDED_PRODUCTS});
    }
  };
};

export const getProductsCategories = ({
  orderType,
  user_id,
}: {
  orderType: string;
  user_id: number;
}) => {
  return async (dispatch: any) => {
    dispatch({
      type: FETCH_START_PRODUCT_CATEGORIES,
    });
    try {
      const productsL1 = await jwtAxios.get(
        `products/category-list/?order_type=${orderType}&user_id=${user_id}`,
      );
      dispatch({
        type: FETCH_SUCCESS_PRODUCT_CATEGORIES,
        payload: productsL1.data.result,
      });
    } catch (err) {
      dispatch({type: FETCH_ERROR_PRODUCT_CATEGORIES});
    }
  };
};

export const getSubProductTypes = ({
  category,
  user_id,
}: {
  category: number;
  user_id: number | string;
}) => {
  return async (dispatch: any) => {
    dispatch({
      type: FETCH_START_SUB_PRODUCT_TYPES,
    });
    try {
      const productsL2 = await jwtAxios.get(
        `products/sub-category-list/?category=${category}&user_id=${user_id}`,
      );
      dispatch({
        type: FETCH_SUCCESS_SUB_PRODUCT_TYPES,
        payload: productsL2.data.result,
      });
    } catch (err) {
      dispatch({type: FETCH_ERROR_SUB_PRODUCT_TYPES});
    }
  };
};

export const getAddons = ({
  category,
  user_id,
}: {
  category: number;
  user_id: number | string;
}) => {
  return async (dispatch: any) => {
    dispatch({
      type: FETCH_START_ADDONS,
    });
    try {
      const addons = await jwtAxios.get(
        `products/addons-category/?sub_category=${category}&user_id=${user_id}`,
      );
      dispatch({
        type: FETCH_SUCCESS_ADDONS,
        payload: addons.data.result,
      });
    } catch (err) {
      dispatch({type: FETCH_ERROR_ADDONS});
    }
  };
};

export const getAddonsOnFalseBoolean = ({
  category,
  user_id,
}: {
  category: number;
  user_id: number | string;
}) => {
  return async (dispatch: any) => {
    dispatch({
      type: FETCH_ADDONS_REQUEST,
    });
    try {
      const addons = await jwtAxios.get(
        `products/company-package/?user_id=${user_id}&package_group_id=${category}`,
      );

      dispatch({
        type: FETCH_REQUEST_SUCCESS_ADDONS,
        payload: addons.data.result,
      });
    } catch (err) {
      dispatch({type: FETCH_REQUEST_ERROR_ADDONS});
    }
  };
};

export const getUpgradedPackages = ({
  order_ref,
  user_id,
  package_id,
}: {
  user_id: string | number;
  order_ref: string | number;
  package_id: string | number;
}) => {
  return async (dispatch: any) => {
    dispatch({
      type: FETCH_START_GET_UPGRADED_PRODUCTS,
    });
    try {
      const res = await jwtAxios.get(
        `products/upgraded-package/?user_id=${user_id}&order_ref=${order_ref}&package_id=${package_id}`,
      );
      dispatch({
        type: FETCH_SUCCESS_GET_UPGRADED_PRODUCTS,
        payload: res.data.result,
      });
    } catch (err) {
      dispatch({type: FETCH_ERROR_GET_UPGRADED_PRODUCTS});
    }
  };
};

export const getUpgradedAddonPackages = ({
  order_ref,
  user_id,
  package_id,
  is_addon_only,
}: {
  user_id: string | number;
  order_ref: string | number;
  is_addon_only: Boolean;
  package_id: string | number;
}) => {
  return async (dispatch: any) => {
    dispatch({
      type: FETCH_START_GET_UPGRADED_PRODUCTS,
    });
    try {
      if (is_addon_only) {
        var res = await jwtAxios.get(
          `products/upgraded-addons-package/?user_id=${user_id}&order_ref=${order_ref}`,
        );
      } else {
        var res = await jwtAxios.get(
          `products/upgraded-addons-package/?user_id=${user_id}&order_ref=${order_ref}&package_id=${package_id}`,
        );
      }

      dispatch({
        type: FETCH_SUCCESS_GET_UPGRADED_PRODUCTS,
        payload: res.data.result,
      });
    } catch (err) {
      dispatch({type: FETCH_ERROR_GET_UPGRADED_PRODUCTS});
    }
  };
};
export const doPayment =
  (dataforPayment: any, user: any) => async (dispatch: any) => {
    try {
      const res = await jwtAxios.get(
        `stripe/place-order-session/?invoice_reference=${
          dataforPayment?.invoice.invoice_reference
        }&amount=${dataforPayment?.invoice.entry_gross_rate * 100}&currency=${
          dataforPayment?.currency
        }&order_user_id=${user?.id}&order_reference=${dataforPayment?.reference}
      `,
      );

 
      if (res?.status === 200) {
        let url = res?.data?.result[0]?.url;
        setTimeout(() => {
          window.location.replace(url);
        }, 2000);
      }
    } catch (error) {
      dispatch(fetchError('something went wrong!'));
    }
  };

export const doOnePayment =
  (dataforPayment: any, user: any) => async (dispatch: any) => {
    try {
      const res = await jwtAxios.get(
        `stripe/onetime-payment-session/?amount=${
          dataforPayment?.invoice.entry_gross_rate * 100
        }&currency=${dataforPayment?.currency}&reference=${
          dataforPayment?.reference
        }
      `,
      );

      if (res?.status ===200) {
        let url = res?.data?.result[0]?.url;
        setTimeout(() => {
          window.location.replace(url);
        }, 500);
      }
    } catch (error) {
      dispatch(fetchError('something went wrong!'));
    }
  };


  export const PayWithCard =(Payload: any,) => async (dispatch: any) => {
    try { 
      
      const res = await jwtAxios.get(
        `stripe/invoice-payment-session/?reference=${Payload.invoice_Id}&amount=${Payload.amount}&currency=${Payload.currency}&user_id=${Payload.user_id}`,
      );
//invoice_Id,amount,currency,user_id
      if (res?.status ===200) {
        let url = res?.data?.result[0]?.url;
        setTimeout(() => {
          window.location.replace(url);
        }, 500);
      }
    } catch (error) {
      dispatch(fetchError('something went wrong!'));
    }
  };

export const PlaceNewOrder = (apiBody: any, user: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: POST_NEW_ORDER_REQUEST,
    });
    try {
      const res = await jwtAxios.post(`orders/order-placement/`, apiBody);
      dispatch({
        type: POST_NEW_ORDER_REQUEST_SUCCESS,
        payload: res.data.result,
      });

      const dataforPayment = res.data.result;

      dispatch(showMessage('Order placed succesfully'));

      if (user?.prepaid) {
        dispatch(doPayment(dataforPayment, user));
      } else {
        setTimeout(() => {
          window.location.replace(
            `/dashboard/orders/detail/${dataforPayment?.reference}`,
          );
        }, 1500);
      }
    } catch (error) {
      dispatch({type: POST_NEW_ORDER_FAIL});
      dispatch(fetchError('Something went wrong!'));
    }
  };
};
