import jwtAxios from '@crema/services/auth/jwt-auth/jwt-api';
import {
  BEE_NOTES,
  CANCEL_HOISTING,CANCEL_HOISTING_SUCCESS,CANCEL_HOISTING_ERROR,
  BEE_NOTES_ERROR,
  GET_MSL_DETAILS,GET_MSL_DETAILS_SUCCESS,GET_MSL_DETAILS_ERROR,
  RENEW_HOISTING,RENEW_HOISTING_SUCCESS,RENEW_HOISTING_ERROR,
  GET_ALL_SHOOTERLIST,
  GET_INVOICE_EDIT_DETAIL,GET_INVOICE_EDIT_DETAIL_SUCCESS,GET_INVOICE_EDIT_DETAIL_ERROR,
  GET_ALL_SHOOTERLIST_SUCCESS,
  GET_ALL_SHOOTERLIST_ERROR,
  BEE_NOTES_SUCCESS,
  GET_ACCOUNT_LIST,
  GET_ACCOUNT_LIST_SUCCESS,
  GET_UNAVAILABLE_LIST,
  GET_UNAVAILABLE_LIST_SUCCESS,
  GET_UNAVAILABLE_LIST_ERROR,
  GET_AVAILABLE_LIST,
  GET_AVAILABLE_LIST_SUCCESS,
  GET_AVAILABLE_LIST_ERROR,
  GET_ACCOUNT_LIST_ERROR,
  GET_COMPANY_LINK_STATUS,
  GET_COMPANY_LINK_STATUS_SUCCESS,
  GET_COMPANY_LINK_STATUS_ERROR,
  GET_PROJECT_FILE,
  GET_PROJECT_FILE_SUCCESS,
  GET_PROJECT_FILE_ERROR,
  POST_COMPANY_INVITATION,
  POST_COMPANY_INVITATION_SUCCESS,
  POST_COMPANY_INVITATION_ERROR,
  GET_ORDER_TOTAL_PRICE,
  GET_ORDER_TOTAL_PRICE_SUCCESS,
  GET_ORDER_TOTAL_PRICE_ERROR,
  DELETE_COMPANY,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_ERROR,
  DELETE_USER_REQ,
  DELETE_USER_REQ_SUCCESS,
  DELETE_USER_REQ_ERROR,
  POST_3D_LOGO,
  POST_3D_LOGO_SUCCESS,
  POST_3D_LOGO_ERROR,
  POST_CLIENT_COMPANY_DETAILS,
  POST_CLIENT_COMPANY_DETAILS_SUCCESS,
  POST_CLIENT_COMPANY_DETAILS_ERROR,
  UPDATE_INVOICE_ENTRY,
  PUT_CLIENT_USER,
  PUT_CLIENT_USER_SUCCESS,
  PUT_CLIENT_USER_ERROR,
  UPDATE_INVOICE_ENTRY_SUCCESS,
  GET_REGION_REQ,
  GET_REGION_REQ_SUCCESS,
  GET_REGION_REQ_ERROR,
  GET_WATER_MARK,
  GET_WATER_MARK_SUCCESS,
  GET_WATER_MARK_ERROR,
  UPDATE_INVOICE_ENTRY_ERROR,
  PUT_CLIENT_UPDATE,
  UPDATE_COMPANY_DETAILS,
  UPDATE_COMPANY_DETAILS_SUCCESS,
  UPDATE_COMPANY_DETAILS_ERROR,
  PUT_CLIENT_UPDATE_SUCCESS,
  ADD_NEW_COMPANY,
  ADD_NEW_COMPANY_SUCCESS,
  ADD_NEW_COMPANY_ERROR,
  PUT_CLIENT_UPDATE_ERROR,
  COMPANY_ACTION,
  GET_GROUPS_REQ,
  GET_GROUPS_REQ_SUCCESS,
  GET_GROUPS_REQ_ERROR,
  GET_LISTING_SITE,
  GET_LISTING_SITE_SUCCESS,
  GET_LISTING_SITE_ERROR,
  GET_PERMISSION_REQ,
  GET_PERMISSION_REQ_SUCCESS,
  GET_PERMISSION_REQ_ERROR,
  ADD_CLIENT_REQ,
  ADD_CLIENT_REQ_SUCCESS,
  ADD_CLIENT_REQ_ERROR,
  ADD_BEE_REQ,
  ADD_BEE_REQ_SUCCESS,
  ADD_BEE_REQ_ERROR,
  GET_MUSIC_REQ,
  GET_MUSIC_REQ_SUCCESS,
  GET_MUSIC_REQ_ERROR,
  GET_COUNTRY_LIST,
  GET_COUNTRY_LIST_SUCCESS,
  GET_COUNTRY_LIST_ERROR,
  DELETE_INVOICE_ENTRY,
  DELETE_INVOICE_ENTRY_SUCCESS,
  DELETE_INVOICE_ENTRY_ERROR,
  APPROVE_INVOICE,
  APPROVE_INVOICE_SUCCESS,
  APPROVE_INVOICE_ERROR,
  POST_APPROVE_INVOICE,
  POST_APPROVE_INVOICE_SUCCESS,
  POST_APPROVE_INVOICE_ERROR,
  COMPANY_ACTION_SUCCESS,
  GET_USER_TYPE,
  GET_USER_TYPE_SUCCESS,
  GET_USER_TYPE_ERROR,
  COMPANY_ACTION_ERROR,
  PUT_YOUTUBE_UPLOAD,
  GET_PREFERENCES,
  GET_PREFERENCES_SUCCESS,
  GET_PREFERENCES_ERROR,
  PUT_YOUTUBE_UPLOAD_SUCCESS,
  GET_PUBLIC_LOGO,
  GET_PUBLIC_LOGO_SUCCESS,
  GET_PUBLIC_LOGO_ERROR,
  PUT_YOUTUBE_UPLOAD_ERROR,
  POST_NEW_INVOICE,
  POST_NEW_INVOICE_SUCCESS,
  POST_NEW_INVOICE_ERROR,
  GET_WEBLINK_DETAILS,
  GET_WEBLINK_DETAILS_SUCCESS,
  GET_WEBLINK_DETAILS_ERROR,
  BILLING_FILTER_INVOICE,
  BILLING_FILTER_INVOICE_ERROR,
  PUT_TOUR_DETAILS,
  PUT_TOUR_DETAILS_SUCCESS,
  PUT_TOUR_DETAILS_ERROR,
  GET_TOUR_THREE_SIXTY,
  GET_TOUR_THREE_SIXTY_SUCCESS,
  GET_TOUR_THREE_SIXTY_ERROR,
  BILLING_FILTER_INVOICE_SUCCESS,
  CANCEL_ORDER_REQUEST,
  CANCEL_ORDER_REQUEST_ERROR,
  CANCEL_ORDER_REQUEST_SUCCESS,
  PUT_CLIENT_NOTES,
  PUT_CLIENT_NOTES_SUCCESS,
  PUT_BEE_UPDATE,
  PUT_BEE_UPDATE_SUCCESS,
  PUT_BEE_UPDATE_ERROR,
  PUT_CLIENT_NOTES_ERROR,
  DELETE_ASSET_GROUP,
  DELETE_ASSET_GROUP_ERROR,
  DELETE_ASSET_GROUP_SUCCESS,
  DELETE_BACKEND_COMPANY_DETAILS,
  DELETE_BACKEND_COMPANY_DETAILS_ERROR,
  DELETE_BACKEND_COMPANY_DETAILS_SUCCESS,
  DELETE_ORDER,
  DELETE_ORDER_ERROR,
  DELETE_ORDER_SPECIAL_REQUEST,
  DELETE_ORDER_SPECIAL_REQUEST_ERROR,
  DELETE_ORDER_SPECIAL_REQUEST_SUCCESS,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_TICKET,
  DELETE_ORDER_TICKET_ERROR,
  DELETE_ORDER_TICKET_SUCCESS,
  DOWNLOAD_BILLING_CSV,
  DOWNLOAD_BILLING_CSV_ERROR,
  DOWNLOAD_BILLING_CSV_SUCCESS,
  DOWNLOAD_INVOICE_CSV,
  DOWNLOAD_INVOICE_CSV_ERROR,
  DOWNLOAD_INVOICE_CSV_SUCCESS,
  DOWNLOAD_STRIPE_CSV,
  DOWNLOAD_STRIPE_CSV_ERROR,
  DOWNLOAD_STRIPE_CSV_SUCCESS,
  FETCH_CLIENT_DROPDOWN_INFORMATION,
  POST_RESHOOT_DETAILS,
  POST_RESHOOT_DETAILS_SUCCESS,
  POST_RESHOOT_DETAILS_ERROR,
  FETCH_CLIENT_DROPDOWN_INFORMATION_ERROR,
  FETCH_CLIENT_DROPDOWN_INFORMATION_SUCCESS,
  FETCH_MANAGEMENT_DETAILS,
  FETCH_MANAGEMENT_DETAILS_ERROR,
  FETCH_MANAGEMENT_DETAILS_SUCCESS,
  FETCH_MANAGEMENT_TABLE_DETAILS,
  FETCH_MANAGEMENT_TABLE_DETAILS_ERROR,
  FETCH_MANAGEMENT_TABLE_DETAILS_SUCCESS,
  FETCH_MENULIST_FAILED,
  FETCH_MENULIST_SUCCESSFULL,
  FETCH_MENU_LIST,
  FETCH_ORDER_DETAILS_REQUEST,
  FETCH_ORDER_DETAILS_REQUEST_ERROR,
  FETCH_ORDER_DETAILS_REQUEST_SUCCESS,
  FETCH_ORDER_TABLE_DETAILS,
  FETCH_ORDER_TABLE_DETAILS_FAILED,
  GET_BROCHURE_REQ,
  GET_BROCHURE_REQ_SUCCESS,
  GET_BROCHURE_REQ_ERROR,
  FETCH_ORDER_TABLE_DETAILS_SUCCESSFULL,
  FETCH_PACKAGE_DROPDOWN,
  FETCH_PACKAGE_DROPDOWN_ERROR,
  FETCH_PACKAGE_DROPDOWN_SUCCESS,
  FETCH_PRODUCT_ADDON_DROPDOWN,
  FETCH_PRODUCT_ADDON_DROPDOWN_ERROR,
  FETCH_PRODUCT_ADDON_DROPDOWN_SUCCESS,
  FETCH_PRODUCT_DROPDOWN,
  FETCH_PRODUCT_DROPDOWN_ERROR,
  FETCH_PRODUCT_DROPDOWN_SUCCESS,
  FETCH_PROPERTY_PRICE_MODIFIERS,
  FETCH_PROPERTY_PRICE_MODIFIERS_ERROR,
  FETCH_PROPERTY_PRICE_MODIFIERS_SUCCESS,
  FETCH_PROPERTY_REQUEST,
  FETCH_PROPERTY_REQUEST_ERROR,
  FETCH_PROPERTY_REQUEST_SUCCESS,
  GET_ACTIVE_ORDER,
  GET_ACTIVE_ORDER_ERROR,
  GET_ACTIVE_ORDER_SUCCESS,
  GET_ALL_USER,
  GET_ALL_USER_ERROR,
  GET_ALL_USER_SUCCESS,
  GET_ANALYTICS_DETAILS,
  GET_ANALYTICS_DETAILS_ERROR,
  GET_ANALYTICS_DETAILS_SUCCESS,
  GET_BACKEND_COMPANY_DETAILS,
  GET_BACKEND_COMPANY_DETAILS_ERROR,
  GET_BACKEND_COMPANY_DETAILS_SUCCESS,
  GET_BACKEND_COMPANY_STATS,
  GET_BACKEND_COMPANY_STATS_ERROR,
  GET_BACKEND_COMPANY_STATS_SUCCESS,
  GET_BACKEND_COUNTRY_STATS,
  GET_BACKEND_COUNTRY_STATS_ERROR,
  GET_BACKEND_COUNTRY_STATS_SUCCESS,
  GET_BACKEND_STRIPE,
  GET_BACKEND_STRIPE_ERROR,
  GET_BACKEND_STRIPE_SUCCESS,
  GET_BEES_LIST,
  GET_BEES_LIST_ERROR,
  GET_BEES_LIST_NEW,
  GET_BEES_LIST_NEW_ERROR,
  GET_BEES_LIST_NEW_SUCCESS,
  GET_BEES_LIST_SUCCESS,
  GET_BEE_FILTER_INVOICE,
  GET_BEE_FILTER_INVOICE_ERROR,
  GET_BEE_FILTER_INVOICE_SUCCESS,
  GET_SLOTS_REQ,
  GET_SLOTS_REQ_SUCCESS,
  GET_SLOTS_REQ_ERROR,
  GET_BEE_KIT_LIST,
  GET_BEE_KIT_LIST_SUCCESS,
  GET_BEE_KIT_LIST_ERROR,
  GET_BEE_INVOICE,
  GET_BEE_INVOICE_DETAILS,
  GET_BEE_INVOICE_DETAILS_ERROR,
  GET_BEE_INVOICE_DETAILS_SUCCESS,
  GET_BEE_INVOICE_ERROR,
  GET_BEE_INVOICE_SUCCESS,
  GET_BILLING_REQUEST,
  GET_BILLING_REQUEST_ERROR,
  GET_BILLING_REQUEST_SUCCESS,
  GET_CALENDAR_MONTH,
  GET_CALENDAR_MONTH_ERROR,
  GET_CALENDAR_MONTH_SUCCESS,
  GET_CALENDER_DAY_VIEW,
  GET_CALENDER_DAY_VIEW_ERROR,
  GET_CALENDER_DAY_VIEW_SUCCESS,
  GET_CALENDER_MONTH_VIEW,
  GET_CALENDER_MONTH_VIEW_AVAIL,
  GET_CALENDER_MONTH_VIEW_AVAIL_ERROR,
  GET_CALENDER_MONTH_VIEW_AVAIL_SUCCESS,
  GET_CALENDER_MONTH_VIEW_DAY,
  GET_CALENDER_MONTH_VIEW_DAY_ERROR,
  GET_CALENDER_MONTH_VIEW_DAY_SUCCESS,
  GET_CALENDER_MONTH_VIEW_ERROR,
  GET_CALENDER_MONTH_VIEW_SUCCESS,
  GET_CARDLESS_EVENT_DETAILS,
  GET_CARDLESS_EVENT_DETAILS_ERROR,
  GET_CARDLESS_EVENT_DETAILS_SUCCESS,
  GET_CARDLESS_EVENT_LIST,
  GET_CARDLESS_EVENT_LIST_ERROR,
  GET_CARDLESS_EVENT_LIST_SUCCESS,
  GET_CARDLESS_PAYMENT,
  GET_CARDLESS_PAYMENT_ERROR,
  GET_CARDLESS_PAYMENT_SUCCESS,
  GET_CLIENT_LIST,
  GET_CLIENT_LIST_ERROR,
  GET_CLIENT_LIST_SUCCESS,
  GET_CLOSED_TICKETS,
  GET_CLOSED_TICKETS_ERROR,
  GET_CLOSED_TICKETS_SUCCESS,
  GET_COMPANY_DETAIL,
  GET_COMPANY_DETAILS,
  GET_COMPANY_DETAILS_ERROR,
  GET_COMPANY_DETAILS_SUCCESS,
  GET_COMPANY_DETAIL_ERROR,
  GET_COMPANY_DETAIL_SUCCESS,
  GET_COMPANY_LIST,
  GET_COMPANY_LIST_ALL,
  GET_COMPANY_LIST_ALL_ERROR,
  GET_COMPANY_LIST_ALL_SUCCESS,
  GET_COMPANY_LIST_ERROR,
  GET_COMPANY_LIST_NEW,
  GET_COMPANY_LIST_NEW_ERROR,
  GET_COMPANY_LIST_NEW_SUCCESS,
  GET_COMPANY_LIST_SUCCESS,
  GET_COMPANY_PROMOCODE_LIST,
  GET_COMPANY_PROMOCODE_LIST_ERROR,
  GET_COMPANY_PROMOCODE_LIST_SUCCESS,
  GET_COMPANY_STATS,
  GET_COMPANY_STATS_ERROR,
  GET_COMPANY_STATS_SUCCESS,
  GET_CREDITOR_CSV,
  GET_CREDITOR_CSV_ERROR,
  GET_CREDITOR_CSV_SUCCESS,
  GET_CREDITOR_DETAILS,
  GET_CREDITOR_DETAILS_ERROR,
  GET_CREDITOR_DETAILS_SUCCESS,
  GET_CREDITOR_LIST,
  GET_CREDITOR_LIST_ERROR,
  GET_CREDITOR_LIST_SUCCESS,
  GET_DAILY_AVAILABILITY,
  GET_DAILY_AVAILABILITY_ERROR,
  GET_DAILY_AVAILABILITY_SUCCESS,
  GET_DASHBOARD_TICKET_DETAIL,
  GET_DASHBOARD_TICKET_DETAIL_ERROR,
  GET_DASHBOARD_TICKET_DETAIL_SUCCESS,
  GET_DATE_RANGE,
  GET_DATE_RANGE_ERROR,
  GET_DATE_RANGE_SUCCESS,
  GET_DEBTOR_CSV,
  GET_DEBTOR_CSV_ERROR,
  GET_DEBTOR_CSV_SUCCESS,
  GET_DEBTOR_DETAILS,
  GET_DEBTOR_DETAILS_ERROR,
  GET_DEBTOR_DETAILS_SUCCESS,
  GET_DEBTOR_LIST,
  GET_DEBTOR_LIST_ERROR,
  GET_DEBTOR_LIST_SUCCESS,
  GET_FAILED_DD,
  GET_FAILED_DD_DETAILS,
  GET_FAILED_DD_DETAILS_ERROR,
  GET_FAILED_DD_DETAILS_SUCCESS,
  GET_FAILED_DD_ERROR,
  GET_FAILED_DD_SUCCESS,
  GET_FILTER_ORDER,
  GET_FILTER_ORDER_ERROR,
  GET_FILTER_ORDER_SUCCESS,
  GET_FILTER_TASK,
  GET_FILTER_TASK_ERROR,
  GET_FILTER_TASK_SUCCESS,
  GET_GROUP_MANUAL_BEES,
  GET_GROUP_MANUAL_BEES_ERROR,
  GET_GROUP_MANUAL_BEES_SUCCESS,
  GET_HOSTING_PERIOD,
  GET_HOSTING_PERIOD_ERROR,
  GET_HOSTING_PERIOD_SUCCESS,
  GET_INFORMATION_PAGE,
  GET_INFORMATION_PAGE_ERROR,
  GET_INFORMATION_PAGE_SUCCESS,
  GET_INVOICE_DETAILS,
  GET_INVOICE_DETAILS_ERROR,
  GET_INVOICE_DETAILS_SUCCESS,
  GET_MANDATE_LIST,
  GET_MANDATE_LIST_ERROR,
  GET_MANDATE_LIST_SUCCESS,
  GET_MANUAL_BEES,
  GET_MANUAL_BEES_ERROR,
  GET_MANUAL_BEES_SUCCESS,
  GET_MATTERPORT_DETAILS,
  GET_MATTERPORT_DETAILS_ERROR,
  GET_MATTERPORT_DETAILS_SUCCESS,
  GET_MENDATE_DETAILS,
  GET_MENDATE_DETAILS_ERROR,
  GET_MENDATE_DETAILS_SUCCESS,
  GET_MONTHLY_AVAILABILITY,
  GET_MONTHLY_AVAILABILITY_ERROR,
  GET_MONTHLY_AVAILABILITY_SUCCESS,
  GET_MOVIES_LIST,
  GET_MOVIES_LIST_ERROR,
  GET_MOVIES_LIST_SUCCESS,
  GET_OPEN_TICKETS,
  GET_OPEN_TICKETS_ERROR,
  GET_OPEN_TICKETS_SUCCESS,
  GET_ORDER_ASSET,
  GET_ORDER_ASSET_ERROR,
  GET_ORDER_ASSET_SUCCESS,
  GET_ORDER_BUTTONS,
  GET_ORDER_BUTTONS_ERROR,
  GET_ORDER_BUTTONS_SUCCESS,
  GET_ORDER_SPECIALREQUEST,
  GET_ORDER_SPECIALREQUEST_ERROR,
  GET_ORDER_SPECIALREQUEST_SUCCESS,
  GET_ORDER_STATS,
  GET_ORDER_STATS_DETAILS,
  GET_ORDER_STATS_DETAILS_ERROR,
  GET_ORDER_STATS_DETAILS_SUCCESS,
  GET_ORDER_STATS_ERROR,
  GET_ORDER_STATS_SUCCESS,
  GET_ORDER_TICEKT_LIST,
  GET_ORDER_TICEKT_LIST_ERROR,
  GET_ORDER_TICEKT_LIST_SUCCESS,
  GET_ORDER_WEBLINK,
  GET_ORDER_WEBLINK_ERROR,
  GET_ORDER_WEBLINK_SUCCESS,
  GET_OVERVIEW_DETAILS,
  GET_OVERVIEW_DETAILS_ERROR,
  GET_OVERVIEW_DETAILS_SUCCESS,
  GET_PAYMENT_DETAILS,
  GET_PAYMENT_DETAILS_ERROR,
  GET_PAYMENT_DETAILS_SUCCESS,
  GET_PAYMENT_LIST,
  GET_PAYMENT_LIST_ERROR,
  GET_PAYMENT_LIST_SUCCESS,
  GET_PAY_DETAILS,
  GET_PAY_DETAILS_ERROR,
  GET_PAY_DETAILS_SUCCESS,
  GET_PROFILE_DETAILS,
  GET_PROFILE_DETAILS_ERROR,
  GET_PROFILE_DETAILS_SUCCESS,
  GET_PROMOCODE_LIST,
  GET_PROMOCODE_LIST_ERROR,
  GET_PROMOCODE_LIST_SUCCESS,
  GET_PROMOCODE_USER_LIST,
  GET_PROMOCODE_USER_LIST_ERROR,
  GET_PROMOCODE_USER_LIST_SUCCESS,
  GET_PROMO_CODE_COMPANY,
  GET_PROMO_CODE_COMPANY_ERROR,
  GET_PROMO_CODE_COMPANY_SUCCESS,
  GET_QUESTIONNAIRE_REQUEST,
  GET_QUESTIONNAIRE_REQUEST_ERROR,
  GET_QUESTIONNAIRE_REQUEST_SUCCESS,
  GET_REDIRECT_FLOWS,
  GET_REDIRECT_FLOWS_ERROR,
  GET_REDIRECT_FLOWS_SUCCESS,
  GET_REDIRECT_FLOW_DETAILS,
  GET_REDIRECT_FLOW_DETAILS_ERROR,
  GET_REDIRECT_FLOW_DETAILS_SUCCESS,
  GET_REGIONAL_STATS,
  GET_REGIONAL_STATS_ERROR,
  GET_REGIONAL_STATS_SUCCESS,
  GET_REQUEST_DROPDOWN,
  GET_REQUEST_DROPDOWN_ERROR,
  GET_REQUEST_DROPDOWN_LIST,
  GET_REQUEST_DROPDOWN_LIST_ERROR,
  GET_REQUEST_DROPDOWN_LIST_SUCCESS,
  GET_REQUEST_DROPDOWN_SUCCESS,
  GET_SAGE_CSV_DOWNLOAD,
  GET_SAGE_CSV_DOWNLOAD_ERROR,
  GET_SAGE_CSV_DOWNLOAD_SUCCESS,
  GET_TASK_DETAILS,
  GET_TASK_DETAILS_ERROR,
  GET_TASK_DETAILS_SUCCESS,
  GET_TASK_LIST,
  GET_TASK_LIST_ERROR,
  GET_TASK_LIST_SUCCESS,
  GET_TICKET_DETAIL,
  POST_NEW_PASSWORD,
  POST_NEW_PASSWORD_SUCCESS,
  POST_NEW_PASSWORD_ERROR,
  GET_TICKET_DETAIL_ERROR,
  GET_TICKET_DETAIL_SUCCESS,
  GET_TICKET_LIST,
  GET_TICKET_LIST_ERROR,
  GET_TICKET_LIST_SUCCESS,
  GET_TRANSCODE_MOVIE,
  GET_TRANSCODE_MOVIE_ERROR,
  GET_TRANSCODE_MOVIE_SUCCESS,
  GET_VIRTUAL_TOOL,
  GET_VIRTUAL_TOOL_ERROR,
  GET_VIRTUAL_TOOL_SUCCESS,
  GET_WEEKLY_AVAILABILITY,
  GET_WEEKLY_AVAILABILITY_ERROR,
  GET_WEEKLY_AVAILABILITY_SUCCESS,
  HOLD_ORDER_REQUEST,
  HOLD_ORDER_REQUEST_ERROR,
  HOLD_ORDER_REQUEST_SUCCESS,
  POSTPOND_CALL,
  POSTPOND_CALL_ERROR,
  POSTPOND_CALL_SUCCESS,
  POST_ALL_REPORTS,
  POST_ALL_REPORTS_ERROR,
  POST_ALL_REPORTS_SUCCESS,
  POST_APPROVE_ASSET_GROUP,
  POST_APPROVE_ASSET_GROUP_ERROR,
  POST_APPROVE_ASSET_GROUP_SUCCESS,
  POST_ARCHIVE_MOVIE,
  POST_ARCHIVE_MOVIE_ERROR,
  POST_ARCHIVE_MOVIE_SUCCESS,
  POST_ASSET_APPROVAL,
  POST_ASSET_APPROVAL_ERROR,
  POST_ASSET_APPROVAL_SUCCESS,
  POST_ASSET_REQUEST,
  POST_ASSET_REQUEST_ERROR,
  POST_ASSET_REQUEST_SUCCESS,
  POST_BEE_NOTES,
  POST_BEE_NOTES_ERROR,
  POST_BEE_NOTES_SUCCESS,
  POST_BEE_SPECIAL_CHARGE,
  POST_BEE_SPECIAL_CHARGE_ERROR,
  POST_BEE_SPECIAL_CHARGE_SUCCESS,
  POST_BEST_BEES,
  POST_BEST_BEES_ERROR,
  POST_BEST_BEES_SUCCESS,
  POST_COMPANY_DETAILS,
  POST_COMPANY_DETAILS_ERROR,
  POST_COMPANY_DETAILS_SUCCESS,
  POST_COMPLETE_TASK,
  POST_COMPLETE_TASK_SUCCESS,
  POST_COMPLETE_TASK_ERROR,
  POST_FEILD_CHANGE_TASK,
  POST_FEILD_CHANGE_TASK_SUCCESS,
  POST_FEILD_CHANGE_TASK_ERROR,
  POST_CLIENT_TASK,
  POST_CLIENT_TASK_SUCCESS,
  POST_CLIENT_TASK_ERROR,
  POST_COMPANY_LIST,
  POST_COMPANY_LIST_ERROR,
  POST_COMPANY_LIST_SUCCESS,
  POST_COMPANY_SIGNUP,
  POST_COMPANY_SIGNUP_ERROR,
  POST_COMPANY_SIGNUP_SUCCESS,
  POST_COUNTRY_REPORTS,
  POST_COUNTRY_REPORTS_ERROR,
  POST_COUNTRY_REPORTS_SUCCESS,
  POST_CREDIT_REQUEST,
  POST_CREDIT_REQUEST_ERROR,
  POST_CREDIT_REQUEST_SUCCESS,
  POST_INVOICE_AMMEND,
  POST_INVOICE_AMMEND_ERROR,
  POST_INVOICE_AMMEND_SUCCESS,
  POST_UNAVAILABLE_SLOT,
  POST_UNAVAILABLE_SLOT_SUCCESS,
  POST_UNAVAILABLE_SLOT_ERROR,
  POST_MANAGEMENT_REPORTS,
  POST_MANAGEMENT_REPORTS_ERROR,
  POST_MANAGEMENT_REPORTS_SUCCESS,
  POST_MARK_PAID_OR_DUE,
  POST_MARK_PAID_OR_DUE_ERROR,
  POST_MARK_PAID_OR_DUE_SUCCESS,
  POST_NEW_MOVIE,
  POST_NEW_MOVIE_ERROR,
  POST_NEW_MOVIE_SUCCESS,
  POST_NEW_PAYMENTLINK,
  POST_NEW_PAYMENTLINK_ERROR,
  POST_NEW_PAYMENTLINK_SUCCESS,
  POST_NEW_TICKET,
  POST_NEW_TICKET_ERROR,
  POST_NEW_TICKET_SUCCESS,
  POST_ORDER_ACTIONS,
  POST_ORDER_ACTIONS_ERROR,
  POST_ORDER_ACTIONS_SUCCESS,
  POST_PENDING_ASSET,
  POST_PENDING_ASSET_ERROR,
  POST_PENDING_ASSET_SUCCESS,
  POST_PROMOCODE,
  POST_PROMOCODE_ERROR,
  POST_PROMOCODE_SUCCESS,
  POST_PROPERTY_REQUEST,
  POST_PROPERTY_REQUEST_ERROR,
  POST_PROPERTY_REQUEST_SUCCESS,
  POST_REFERRAL_REPORT,
  POST_REFERRAL_REPORT_ERROR,
  POST_REFERRAL_REPORT_SUCCESS,
  POST_REGENERATE_ASSET_GROUP,
  POST_REGENERATE_ASSET_GROUP_ERROR,
  POST_REGENERATE_ASSET_GROUP_SUCCESS,
  POST_RESTORE_MOVIE,
  POST_RESTORE_MOVIE_ERROR,
  POST_RESTORE_MOVIE_SUCCESS,
  POST_SET_ASSET_APPROVAL,
  POST_SET_ASSET_APPROVAL_ERROR,
  POST_SET_ASSET_APPROVAL_SUCCESS,
  POST_SHOOTER_ALLOCATION,
  POST_SHOOTER_ALLOCATION_ERROR,
  POST_SHOOTER_ALLOCATION_SUCCESS,
  POST_SHOOTER_AVAILABILITY,
  POST_SHOOTER_AVAILABILITY_ERROR,
  POST_SHOOTER_AVAILABILITY_SUCCESS,
  POST_SPECIAL_CHARGE,
  POST_SPECIAL_CHARGE_ERROR,
  POST_SPECIAL_CHARGE_SUCCESS,
  POST_SUPPORT_NOTES,
  POST_SUPPORT_NOTES_ERROR,
  POST_SUPPORT_NOTES_SUCCESS,
  POST_TICKET_ACTION,
  POST_TICKET_ACTION_ERROR,
  POST_TICKET_ACTION_SUCCESS,
  POST_ZIP_ASSET_GROUP,
  POST_ZIP_ASSET_GROUP_ERROR,
  POST_ZIP_ASSET_GROUP_SUCCESS,
  PUT_BULK_REQUEST,
  PUT_BULK_REQUEST_ERROR,
  PUT_BULK_REQUEST_SUCCESS,
  PUT_CANCEL_HOISTING,
  PUT_CANCEL_HOISTING_ERROR,
  PUT_CANCEL_HOISTING_SUCCESS,
  PUT_CHECKLIST_REQUEST,
  PUT_CHECKLIST_REQUEST_ERROR,
  PUT_CHECKLIST_REQUEST_SUCCESS,
  PUT_CONTACT_AND_LOCATION,
  PUT_CONTACT_AND_LOCATION_ERROR,
  PUT_CONTACT_AND_LOCATION_SUCCESS,
  PUT_INVOICE_BEE_RATE_REQUEST,
  PUT_INVOICE_BEE_RATE_REQUEST_ERROR,
  PUT_INVOICE_BEE_RATE_REQUEST_SUCCESS,
  PUT_KEYPICKUP_REQUEST,
  PUT_KEYPICKUP_REQUEST_ERROR,
  PUT_KEYPICKUP_REQUEST_SUCCESS,
  PUT_MANAGEMENT_ORDER_REQUEST,
  PUT_MANAGEMENT_ORDER_REQUEST_ERROR,
  PUT_MANAGEMENT_ORDER_REQUEST_SUCCESS,
  PUT_MARK_PAID,
  PUT_MARK_PAID_ERROR,
  PUT_MARK_PAID_SUCCESS,
  PUT_MOVIE_DETAIL,
  PUT_MOVIE_DETAIL_ERROR,
  PUT_MOVIE_DETAIL_SUCCESS,
  PUT_ORDER_COMPLETION,
  PUT_ORDER_COMPLETION_ERROR,
  PUT_ORDER_COMPLETION_SUCCESS,
  PUT_ORDER_RENEW_REQUEST,
  PUT_ORDER_RENEW_REQUEST_ERROR,
  PUT_ORDER_RENEW_REQUEST_SUCCESS,
  PUT_ORDER_REQUEST,
  PUT_ORDER_REQUEST_ERROR,
  PUT_ORDER_REQUEST_SUCCESS,
  PUT_ORDER_SPECIAL_REQUEST,
  PUT_ORDER_SPECIAL_REQUEST_ERROR,
  PUT_ORDER_SPECIAL_REQUEST_SUCCESS,
  PUT_PROPERTY_REQUEST,
  DEL_UNAVAILABLE_SLOT,
  DEL_UNAVAILABLE_SLOT_SUCCESS,
  DEL_UNAVAILABLE_SLOT_ERROR,
  SUPPORT_NOTES,
  SUPPORT_NOTES_SUCCESS,
  SUPPORT_NOTES_ERROR,
  PUT_PROPERTY_REQUEST_ERROR,
  PUT_PROPERTY_REQUEST_SUCCESS,
  PUT_REGENERATE_ORDER,
  PUT_REGENERATE_ORDER_ERROR,
  PUT_REGENERATE_ORDER_SUCCESS,
  RESUME_ORDER_REQUEST,
  RESUME_ORDER_REQUEST_ERROR,
  RESUME_ORDER_REQUEST_SUCCESS,
  UPDATE_PACKAGE_ADDON,
  UPDATE_PACKAGE_ADDON_ERROR,
  UPDATE_PACKAGE_ADDON_SUCCESS,
  POST_AMEND_INVOICE,
  POST_AMEND_INVOICE_SUCCESS,
  POST_AMEND_INVOICE_ERROR,
  POST_AMEND_PHOTO,
  POST_AMEND_PHOTO_SUCCESS,
  POST_AMEND_PHOTO_ERROR,
  POST_AMEND_BROUCHER,
  POST_AMEND_BROUCHER_SUCCESS,
  POST_AMEND_BROUCHER_ERROR,
  POST_AMEND_FLOORPLAN,
  POST_AMEND_FLOORPLAN_SUCCESS,
  POST_AMEND_FLOORPLAN_ERROR,

} from '../../types/actions/Dashboard.action';
import {
  fetchError,
  hideMessage,
  showMessage,
  showWarning,
} from '../actions/Common';

const downloadFile = (file: any, fileName: string) => {
  const url = window.URL.createObjectURL(new Blob([file]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}.csv`);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

const downloadZIPFile = (file: any) => {
  const url = file;
  const link = document.createElement('a');
  link.href = url;

  link.click();
  link.remove();
};

const downloadPDF = (file: any, fileName: string) => {
  const blob = new Blob([file]);

  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.download = `${fileName}.pdf`;
  link.click();
  URL.revokeObjectURL(url);
};

const downloadAsset = (URL_STRING: string) => {
  const link = document.createElement('a');

  link.href = URL_STRING;
  const parsedUrl = new URL(URL_STRING);
  const pathname = parsedUrl.pathname;
  const filename = pathname.substring(pathname.lastIndexOf('/') + 1);
  link.download = filename;

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
};
export const getMenuList = (user_types: string | undefined) => {
  return async (dispatch: any) => {
    dispatch({
      type: FETCH_MENU_LIST,
    });
    try {
      const menuList = await jwtAxios.get(
        `dashboard/menu-list/?user_types=${user_types}`,
      );
      dispatch({
        type: FETCH_MENULIST_SUCCESSFULL,
        payload: menuList.data.result,
      });
    } catch (err) {
      dispatch({type: FETCH_MENULIST_FAILED});
      dispatch(fetchError('Something went wrong!'));
    }
  };
};

interface orderListProps {
  user_id?: number;
  offset?: number;
}

interface IChecklist {
  ref: number | undefined;
  id: number | undefined;
  shoot_file_numbering?: boolean | undefined;
  correct_preferences?: boolean | undefined;
  all_rooms_shoot?: boolean | undefined;
}

export const getOrderList = ({user_id, offset}: orderListProps) => {
  return async (dispatch: any) => {
    dispatch({
      type: FETCH_ORDER_TABLE_DETAILS,
    });
    try {
      const orderDetails = await jwtAxios.get(
        `dashboard/order-list/?user_id=${user_id}&offset=${offset}`,
      );
      dispatch({
        type: FETCH_ORDER_TABLE_DETAILS_SUCCESSFULL,
        payload: orderDetails.data,
      });
    } catch (err) {
      dispatch({type: FETCH_ORDER_TABLE_DETAILS_FAILED});
      dispatch(fetchError('Something went wrong!'));
    }
  };
};

interface IorderDetailsParams {
  reference: string;
  userId?: string | undefined;
  code?: string | undefined;
}
export const getOrderDetails = ({
  reference,
  userId,
  code,
}: IorderDetailsParams) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: FETCH_ORDER_DETAILS_REQUEST,
      });

      const {data} = await jwtAxios.get(
        `orders/order-details/?ref=${reference}`,
      );

      dispatch({
        type: FETCH_ORDER_DETAILS_REQUEST_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: FETCH_ORDER_DETAILS_REQUEST_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const getOrderManagementTables = (ref: number) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: FETCH_MANAGEMENT_DETAILS,
      });

      const {data} = await jwtAxios.get(
        `orders/order-management-tables/?ref=${ref}`,
      );

      dispatch({
        type: FETCH_MANAGEMENT_DETAILS_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: FETCH_MANAGEMENT_DETAILS_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const getOrderAssetDetails = (AssetPayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_ORDER_ASSET,
      });

      const {data} = await jwtAxios.get(
        `orders/order-assets/?ref=${AssetPayload?.ref}&user_type=${AssetPayload?.userType}`,
      );

      dispatch({
        type: GET_ORDER_ASSET_SUCCESS,
        payload: data.result,
      });

      if (
        data?.result?.context_data?.is_360_tour ||
        data?.result?.context_data?.is_360_hosting
      ) {
        dispatch(getTour_ThreeSixty({REF: AssetPayload?.ref}));
      }
    } catch (error) {
      dispatch({
        type: GET_ORDER_ASSET_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const getOrderWeblinkDetails = (AssetPayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_ORDER_WEBLINK,
      });

      const {data} = await jwtAxios.get(
        `orders/order-assets/weblink/?order_reference=${AssetPayload?.ref}`,
      );

      dispatch({
        type: GET_ORDER_WEBLINK_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: GET_ORDER_WEBLINK_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const getTaskList = (getTaskListPayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_TASK_LIST,
      });

      const {data} = await jwtAxios.get(
        `/dashboard/task-list/?offset=${getTaskListPayload?.offset}`,
      );

      dispatch({
        type: GET_TASK_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_TASK_LIST_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const getTaskDetails = (getTaskDetailsPayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_TASK_DETAILS,
      });

      if (getTaskDetailsPayload?.invoice_id) {
        var {data} = await jwtAxios.get(
          `dashboard/task-details/?task_id=${getTaskDetailsPayload?.id}&invoice_id=${getTaskDetailsPayload?.invoice_id}`,
        );
      } else {
        var {data} = await jwtAxios.get(
          `dashboard/task-details/?task_id=${getTaskDetailsPayload?.id}`,
        );
      }

      dispatch({
        type: GET_TASK_DETAILS_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: GET_TASK_DETAILS_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const getCalenderMonthView = (getCalenderMonthViewPayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_CALENDER_MONTH_VIEW,
      });

      let url = `scheduler/month/availability/?user_id=${getCalenderMonthViewPayload.user_id}&month=${getCalenderMonthViewPayload.month}&year=${getCalenderMonthViewPayload.year}&distance=${getCalenderMonthViewPayload.distance}`;
      if (getCalenderMonthViewPayload.city != '') {
        url += `&city=${getCalenderMonthViewPayload.city}`;
      }

      const {data} = await jwtAxios.get(url);

      dispatch({
        type: GET_CALENDER_MONTH_VIEW_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: GET_CALENDER_MONTH_VIEW_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const getCalenderMonthViewFilter = (
  getCalenderMonthViewPayload: any,
) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_CALENDER_MONTH_VIEW,
      });

      const {data} = await jwtAxios.get(
        `scheduler/tasks-calendar/month-view/?user_id=${getCalenderMonthViewPayload.user_id}&month=${getCalenderMonthViewPayload.month}&year=${getCalenderMonthViewPayload.year}&task_type=${getCalenderMonthViewPayload?.task_type}&target=${getCalenderMonthViewPayload?.target}`,
      );

      dispatch({
        type: GET_CALENDER_MONTH_VIEW_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: GET_CALENDER_MONTH_VIEW_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const getCalenderMonthViewDayTaskTarget = (
  getCalenderMonthViewPayload: any,
) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_CALENDER_MONTH_VIEW_DAY,
      });

      const {data} = await jwtAxios.get(
        getCalenderMonthViewPayload?.search_key
          ? `scheduler/tasks-calendar/day-view/?user_id=${getCalenderMonthViewPayload.user_id}&month=${getCalenderMonthViewPayload.month}&year=${getCalenderMonthViewPayload.year}&day=${getCalenderMonthViewPayload.day}&task_type=${getCalenderMonthViewPayload?.task_type}&target=${getCalenderMonthViewPayload?.target}&search_key=${getCalenderMonthViewPayload?.search_key}`
          : `scheduler/tasks-calendar/day-view/?user_id=${getCalenderMonthViewPayload.user_id}&month=${getCalenderMonthViewPayload.month}&year=${getCalenderMonthViewPayload.year}&day=${getCalenderMonthViewPayload.day}&task_type=${getCalenderMonthViewPayload?.task_type}&target=${getCalenderMonthViewPayload?.target}`,
      );

      dispatch({
        type: GET_CALENDER_MONTH_VIEW_DAY_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: GET_CALENDER_MONTH_VIEW_DAY_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const getCalenderMonthViewDay = (getCalenderMonthViewPayload: any) => {
  return async (dispatch: any) => {
    try {

      
      dispatch({
        type: GET_CALENDER_MONTH_VIEW_DAY,
      });

      const {data} = await jwtAxios.get(
        getCalenderMonthViewPayload?.task_type
          ? `scheduler/tasks-calendar/day-view/?user_id=${getCalenderMonthViewPayload.user_id}&month=${getCalenderMonthViewPayload.month}&year=${getCalenderMonthViewPayload.year}&day=${getCalenderMonthViewPayload.day}&task_type=${getCalenderMonthViewPayload?.task_type}&target=${getCalenderMonthViewPayload?.target}&search_key=${getCalenderMonthViewPayload?.search_key}`
          : `scheduler/tasks-calendar/day-view/?user_id=${getCalenderMonthViewPayload.user_id}&month=${getCalenderMonthViewPayload.month}&year=${getCalenderMonthViewPayload.year}&day=${getCalenderMonthViewPayload.day}`,
      );

      dispatch({
        type: GET_CALENDER_MONTH_VIEW_DAY_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: GET_CALENDER_MONTH_VIEW_DAY_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const getCalenderMonthViewAvailability = (
  getCalenderMonthViewAvailabilityPayload: any,
) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_CALENDER_MONTH_VIEW_AVAIL,
      });

      let url = `scheduler/month/availability/?user_id=${getCalenderMonthViewAvailabilityPayload.user_id}&month=${getCalenderMonthViewAvailabilityPayload.month}&year=${getCalenderMonthViewAvailabilityPayload.year}&distance=${getCalenderMonthViewAvailabilityPayload.distance}`;
      if (getCalenderMonthViewAvailabilityPayload.city != '') {
        url += `&city=${getCalenderMonthViewAvailabilityPayload.city}`;
      }
      const {data} = await jwtAxios.get(url);

      dispatch({
        type: GET_CALENDER_MONTH_VIEW_AVAIL_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: GET_CALENDER_MONTH_VIEW_AVAIL_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const getCalenderDayView = (getCalenderDayViewPayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_CALENDER_DAY_VIEW,
      });

      const {data} = await jwtAxios.get(
        `other/shoots-overview/?country=${getCalenderDayViewPayload.country}&date=${getCalenderDayViewPayload.date}`,
      );

      dispatch({
        type: GET_CALENDER_DAY_VIEW_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: GET_CALENDER_DAY_VIEW_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const getOverviewDetails = (getOverviewDetailsPayload?: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_OVERVIEW_DETAILS,
      });

      if (getOverviewDetailsPayload) {
        var {data} = await jwtAxios.get(
          `other/shoots-overview/?country=${getOverviewDetailsPayload?.type}&date=${getOverviewDetailsPayload?.selectedDate}`,
        );
      } else {
        var {data} = await jwtAxios.get(`other/shoots-overview/`);
      }

      dispatch({
        type: GET_OVERVIEW_DETAILS_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: GET_OVERVIEW_DETAILS_ERROR,
      });
      dispatch(fetchError());
    }
  };
};
export const filterTask = (filterTaskPayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_FILTER_TASK,
      });

      const {data} = await jwtAxios.get(
        `dashboard/task-filter/?active_only=${
          filterTaskPayload?.active_only ? 'on' : 'off'
        }&with_complete=${
          filterTaskPayload?.with_complete ? 'on' : 'off'
        }&task_type=${filterTaskPayload?.task_type}&target=${
          filterTaskPayload?.target
        }&search_key=${filterTaskPayload?.search_key}&offset=${
          filterTaskPayload?.offset
        }`,
      );

      dispatch({
        type: GET_FILTER_TASK_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_FILTER_TASK_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const getOrderStats = (getOrderStatsPayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_ORDER_STATS_DETAILS,
      });

      const {data} = await jwtAxios.get(
        `orders/order-stats/?order_reference=${getOrderStatsPayload.ref}`,
      );

      dispatch({
        type: GET_ORDER_STATS_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_ORDER_STATS_DETAILS_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

interface IrefType {
  ref: number;
  userId: number;
}
export const getOrderManagementTablesDetails = ({ref, userId}: IrefType) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: FETCH_MANAGEMENT_TABLE_DETAILS,
      });

      const {data} = await jwtAxios.get(
        `/orders/order-management/?ref=${ref}&user_id=${userId}`,
      );

      dispatch({
        type: FETCH_MANAGEMENT_TABLE_DETAILS_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: FETCH_MANAGEMENT_TABLE_DETAILS_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const getClientDropDown = () => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: FETCH_CLIENT_DROPDOWN_INFORMATION,
      });

      const {data} = await jwtAxios.get(
        `/orders/order-management/client-dropdown/`,
      );

      dispatch({
        type: FETCH_CLIENT_DROPDOWN_INFORMATION_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: FETCH_CLIENT_DROPDOWN_INFORMATION_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const getPackageArray = (payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: FETCH_PACKAGE_DROPDOWN,
      });

      const {data} = await jwtAxios.get(
        payload.name
          ? `/orders/order-management/package-dropdown/?package_name=${payload.name}&user_id=${payload.userId}`
          : `/orders/order-management/company-package-dropdown/?company_package_name=&user_id=${payload.userId}`,
      );

      dispatch({
        type: FETCH_PACKAGE_DROPDOWN_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: FETCH_PACKAGE_DROPDOWN_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const getProductArray = (payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: FETCH_PRODUCT_DROPDOWN,
      });

      const {data} = await jwtAxios.get(
        `/orders/order-management/product-dropdown/?product_slug=${payload.name}&user_id=${payload.userId}`,
      );

      dispatch({
        type: FETCH_PRODUCT_DROPDOWN_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: FETCH_PRODUCT_DROPDOWN_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const getProductAddonsArray = (payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: FETCH_PRODUCT_ADDON_DROPDOWN,
      });

      const {data} = await jwtAxios.get(
        `/orders/order-management/addon-dropdown/?addon_name=${payload.name}&user_id=${payload.userId}`,
      );

      dispatch({
        type: FETCH_PRODUCT_ADDON_DROPDOWN_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: FETCH_PRODUCT_ADDON_DROPDOWN_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const getPropertyStatus = () => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: FETCH_PROPERTY_REQUEST,
      });

      const {data} = await jwtAxios.get(
        `/orders/order-management/property-status-choices/`,
      );

      dispatch({
        type: FETCH_PROPERTY_REQUEST_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: FETCH_PROPERTY_REQUEST_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const getPriceModifiers = () => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: FETCH_PROPERTY_PRICE_MODIFIERS,
      });

      const {data} = await jwtAxios.get(
        `orders/order-management/property-price-modifier-choices/`,
      );

      dispatch({
        type: FETCH_PROPERTY_PRICE_MODIFIERS_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: FETCH_PROPERTY_PRICE_MODIFIERS_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const getRequestDropDownList = () => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_REQUEST_DROPDOWN_LIST,
      });

      const {data} = await jwtAxios.get(
        `orders/order-management/request/drop-down/`,
      );

      dispatch({
        type: GET_REQUEST_DROPDOWN_LIST_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: GET_REQUEST_DROPDOWN_LIST_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const getRequestData = (ref: number) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_REQUEST_DROPDOWN,
      });

      const {data} = await jwtAxios.get(
        `orders/order-management/request/?ref=${ref}`,
      );

      dispatch({
        type: GET_REQUEST_DROPDOWN_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: GET_REQUEST_DROPDOWN_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const getOrderActionButtons = (ActionButtonPayload: IrefType) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_ORDER_BUTTONS,
      });

      const {data} = await jwtAxios.get(
        `/orders/order-management/action-buttons/?ref=${ActionButtonPayload.ref}&user_id=${ActionButtonPayload.userId}`,
      );

      dispatch({
        type: GET_ORDER_BUTTONS_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: GET_ORDER_BUTTONS_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const getAllTickets = (getAllTicketsPayload: {
  offset: number;
  status?: string;
  querry: string;
}) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_TICKET_LIST,
      });

      if (getAllTicketsPayload?.status) {
        var {data} = await jwtAxios.get(
          `tickets/ticket-list/?offset=${getAllTicketsPayload?.offset}&status=${getAllTicketsPayload?.status}&query=${getAllTicketsPayload?.query}`,
        );
      } else {
        var {data} = await jwtAxios.get(
          `tickets/ticket-list/?offset=${getAllTicketsPayload?.offset}`,
        );
      }

      dispatch({
        type: GET_TICKET_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_TICKET_LIST_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const getTour_ThreeSixty = (Payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_TOUR_THREE_SIXTY,
      });

      var {data} = await jwtAxios.get(
        `orders/tour-360/?order_ref=${Payload?.REF}`,
      );
      dispatch({
        type: GET_TOUR_THREE_SIXTY_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: GET_TOUR_THREE_SIXTY_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const Update_Tour_ThreeSixty = (Payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: PUT_TOUR_DETAILS,
      });

      var {data} = await jwtAxios.put(`orders/tour-360/`, Payload);
      dispatch({
        type: PUT_TOUR_DETAILS_SUCCESS,
        payload: data,
      });

      dispatch(showMessage('Tour links updated'));
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      dispatch({
        type: PUT_TOUR_DETAILS_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const getOpenTickets = (getAllTicketsPayload: {offset: number}) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_OPEN_TICKETS,
      });

      var {data} = await jwtAxios.get(
        `tickets/ticket-list/?offset=${getAllTicketsPayload?.offset}&status=open`,
      );
      dispatch({
        type: GET_OPEN_TICKETS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_OPEN_TICKETS_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const getWebLinks = (Payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_WEBLINK_DETAILS,
      });

      var {data} = await jwtAxios.get(
        `orders/order-assets/weblink/?key=${Payload.key}&slug=${Payload.slug}`,
      );
      dispatch({
        type: GET_WEBLINK_DETAILS_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: GET_WEBLINK_DETAILS_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const getCloseTickets = (getAllTicketsPayload: {
  offset: number;
  status?: string;
}) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_CLOSED_TICKETS,
      });

      var {data} = await jwtAxios.get(
        `tickets/ticket-list/?offset=${getAllTicketsPayload?.offset}&status=closed`,
      );

      dispatch({
        type: GET_CLOSED_TICKETS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_CLOSED_TICKETS_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const getTicketDetails = (ticketPayload: {id: string | number}) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_TICKET_DETAIL,
      });

      const {data} = await jwtAxios.get(
        `tickets/ticket-details/?ticket_id=${ticketPayload.id}`,
      );

      dispatch({
        type: GET_TICKET_DETAIL_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: GET_TICKET_DETAIL_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const getDashBoardTicketDetails = (ticketPayload: {
  id: string | number;
}) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_DASHBOARD_TICKET_DETAIL,
      });

      const {data} = await jwtAxios.get(
        `dashboard/ticket-details/?ticket_id=${ticketPayload.id}`,
      );

      dispatch({
        type: GET_DASHBOARD_TICKET_DETAIL_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: GET_DASHBOARD_TICKET_DETAIL_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const getOrderTicketList = (getOrderTicketListPayload: {
  id: string | number;
}) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_ORDER_TICEKT_LIST,
      });

      const {data} = await jwtAxios.get(
        `dashboard/order-tickets/?order_reference=${getOrderTicketListPayload?.id}`,
      );

      dispatch({
        type: GET_ORDER_TICEKT_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_ORDER_TICEKT_LIST_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const getVirtualTool = (payload: {code: string | number}) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_VIRTUAL_TOOL,
      });

      const {data} = await jwtAxios.get(
        `orders/virtual-view-tool/?code=${payload?.code}`,
      );

      dispatch({
        type: GET_VIRTUAL_TOOL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_VIRTUAL_TOOL_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const getOrderFilter = (getOrderFilterPayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_FILTER_ORDER,
      });

      var {data} = await jwtAxios.get(
        `dashboard/order-filter/?user_id=${getOrderFilterPayload?.user_id}&bee=${getOrderFilterPayload?.beeId}&company_id=${getOrderFilterPayload?.companyId}&status=${getOrderFilterPayload?.status}&query=${getOrderFilterPayload.searchKey}&package_id=${getOrderFilterPayload.packageId}&addons_id=${getOrderFilterPayload.addonId}&offset=${getOrderFilterPayload.offset}&sort_by_order_date=${getOrderFilterPayload.sort_by_order_date}&sort_by_shoot_date=${getOrderFilterPayload.sort_by_shoot_date}`,
      );

      dispatch({
        type: GET_FILTER_ORDER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_FILTER_ORDER_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const getBillingList = (getBillingListPayload: {
  id: string | number;
  offset: any;
}) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_BILLING_REQUEST,
      });

      const {data} = await jwtAxios.get(
        `dashboard/invoice-list/?user_id=${getBillingListPayload.id}&offset=${getBillingListPayload?.offset}`,
      );

      dispatch({
        type: GET_BILLING_REQUEST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_BILLING_REQUEST_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const getQuestionnaire = (getQuestionnairePayload: {id: string}) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_QUESTIONNAIRE_REQUEST,
      });

      const {data} = await jwtAxios.get(
        `dashboard/questionnaire-details/?questionnaire_id=${getQuestionnairePayload?.id}`,
      );

      dispatch({
        type: GET_QUESTIONNAIRE_REQUEST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_QUESTIONNAIRE_REQUEST_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const getOrderSpecialRequest = (getOrderSpecialRequestPayload: {
  id: string | number;
}) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_ORDER_SPECIALREQUEST,
      });

      const {data} = await jwtAxios.get(
        `dashboard/order-special-request/?order_reference=${getOrderSpecialRequestPayload?.id}`,
      );

      dispatch({
        type: GET_ORDER_SPECIALREQUEST_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: GET_ORDER_SPECIALREQUEST_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const getBeesList = (getBeesListPayload: {name: string}) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_BEES_LIST,
      });

      const {data} = await jwtAxios.get(
        `dashboard/bee-user-list/?offset=0&search_key=${getBeesListPayload.name}`,
      );

      dispatch({
        type: GET_BEES_LIST_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: GET_BEES_LIST_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const getAllUserList = (getAllBeesListPayload: {
  userId: number | string;
}) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_ALL_USER,
      });

      const {data} = await jwtAxios.get(
        `dashboard/user-list/?user_id=${getAllBeesListPayload?.userId}`,
      );

      dispatch({
        type: GET_ALL_USER_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_USER_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const getCompanyList = (getCompanyListPayload: {name: string}) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_COMPANY_LIST,
      });

      const {data} = await jwtAxios.get(
        `organisations/company-list/?offset=0&search_key=${getCompanyListPayload.name}`,
      );

      dispatch({
        type: GET_COMPANY_LIST_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: GET_COMPANY_LIST_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const getRegion = () => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_REGION_REQ,
      });

      const {data} = await jwtAxios.get(`accounts/get-region-list/`);

      dispatch({
        type: GET_REGION_REQ_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: GET_REGION_REQ_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const getBrochureList = () => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_BROCHURE_REQ,
      });

      const {data} = await jwtAxios.get(`accounts/get-brochure-list/`);

      dispatch({
        type: GET_BROCHURE_REQ_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: GET_BROCHURE_REQ_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const getListingSite = () => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_LISTING_SITE,
      });

      const {data} = await jwtAxios.get(`accounts/get-listingsite-list/`);

      dispatch({
        type: GET_LISTING_SITE_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: GET_LISTING_SITE_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const getPublicLogo = () => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_PUBLIC_LOGO,
      });

      const {data} = await jwtAxios.get(`accounts/get-publiclogo-list/`);

      dispatch({
        type: GET_PUBLIC_LOGO_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: GET_PUBLIC_LOGO_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const getWaterMarkList = () => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_WATER_MARK,
      });

      const {data} = await jwtAxios.get(`accounts/get-watermark-list/`);

      dispatch({
        type: GET_WATER_MARK_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: GET_WATER_MARK_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const getAccountList = () => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_ACCOUNT_LIST,
      });

      const {data} = await jwtAxios.get(`accounts/get-account-list/`);

      dispatch({
        type: GET_ACCOUNT_LIST_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: GET_ACCOUNT_LIST_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetProjectList = () => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_PROJECT_FILE,
      });

      const {data} = await jwtAxios.get(`accounts/get-projectfile-list/`);

      dispatch({
        type: GET_PROJECT_FILE_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: GET_PROJECT_FILE_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const getBackendCompanyDetails = (getCompanyDetailsPayload: {
  id: number;
  user_id: number;
}) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_BACKEND_COMPANY_DETAILS,
      });

      const {data} = await jwtAxios.get(
        `accounts/get-company-details/?company_id=${getCompanyDetailsPayload?.id}`,
      );

      dispatch({
        type: GET_BACKEND_COMPANY_DETAILS_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: GET_BACKEND_COMPANY_DETAILS_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const deleteBackendCompanyDetails = (
  deleteCompanyDetailsPayload: any,
) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: DELETE_BACKEND_COMPANY_DETAILS,
      });

      const {data} = await jwtAxios.delete(
        `dashboard/backend/company-details/`,
        deleteCompanyDetailsPayload,
      );

      dispatch({
        type: DELETE_BACKEND_COMPANY_DETAILS_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: DELETE_BACKEND_COMPANY_DETAILS_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const updateChecklistData = (checkListData: IChecklist) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: PUT_CHECKLIST_REQUEST,
      });

      const {data} = await jwtAxios.put(
        `/orders/order-management/check-list/`,
        checkListData,
      );

      dispatch({
        type: PUT_CHECKLIST_REQUEST_SUCCESS,
        payload: data.result,
      });

      dispatch(showMessage('Checklist updated'));
    } catch (error) {
      dispatch({
        type: PUT_CHECKLIST_REQUEST_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const UpdateContactAndLocation = (
  UpdateContactAndLocationData: any,
  closeModal: () => void,
) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: PUT_CONTACT_AND_LOCATION,
      });

      const {data} = await jwtAxios.put(
        `orders/order-management/update-property-details/`,
        UpdateContactAndLocationData,
      );

      dispatch({
        type: PUT_CONTACT_AND_LOCATION_SUCCESS,
        payload: data.result,
      });

      dispatch(showMessage('Contact details updated'));

      dispatch(getOrderDetails({reference: UpdateContactAndLocationData?.ref}));
      closeModal(false);
    } catch (error) {
      dispatch({
        type: PUT_CONTACT_AND_LOCATION_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const updateManagementOrder = (ManagementPayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: PUT_MANAGEMENT_ORDER_REQUEST,
      });

      const {data} = await jwtAxios.put(
        `/orders/order-management/`,
        ManagementPayload,
      );

      dispatch({
        type: PUT_MANAGEMENT_ORDER_REQUEST_SUCCESS,
        payload: data.result,
      });

      dispatch(showMessage('Product Information updated'));
      setTimeout(() => {
        dispatch(
          getOrderManagementTablesDetails({
            ref: ManagementPayload?.ref,
            userId: ManagementPayload?.user,
          }),
        );
      }, 2000);
    } catch (error) {
      dispatch({
        type: PUT_MANAGEMENT_ORDER_REQUEST_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const updatePropertyDetails = (PropertyDetails: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: PUT_PROPERTY_REQUEST,
      });

      const {data} = await jwtAxios.put(
        `/orders/order-management/update-property-details/`,
        PropertyDetails,
      );

      dispatch({
        type: PUT_PROPERTY_REQUEST_SUCCESS,
        payload: data.result,
      });

      dispatch(showMessage('Property updated'));
    } catch (error) {
      dispatch({
        type: PUT_PROPERTY_REQUEST_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const PostNewRequest = (postPaylaod: any, renewFun: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: POST_PROPERTY_REQUEST,
      });

      const {data} = await jwtAxios.post(
        `/orders/order-management/request/?ref=${postPaylaod?.ref}/`,
        postPaylaod,
      );

      dispatch({
        type: POST_PROPERTY_REQUEST_SUCCESS,
        payload: data.result,
      });
      dispatch(showMessage('Request added'));
      setTimeout(() => {
        dispatch(getRequestData(postPaylaod?.ref));
        renewFun({
          addon: '',
          text: '',
        });
      }, 2000);
    } catch (error) {
      dispatch({
        type: POST_PROPERTY_REQUEST_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const Post3dLogo = (postPaylaod: any, renewFun?: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: POST_3D_LOGO,
      });

      const {data} = await jwtAxios.post(
        `dashboard/approve-logo3d-task/`,
        postPaylaod,
      );

      dispatch({
        type: POST_3D_LOGO_SUCCESS,
        payload: data.result,
      });
      dispatch(showMessage('Request added'));
    } catch (error) {
      dispatch({
        type: POST_3D_LOGO_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const PostCompanySignUp = (postPaylaod: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: POST_COMPANY_SIGNUP,
      });

      const {data} = await jwtAxios.post(
        `accounts/signup-with-company/`,
        postPaylaod,
      );

      dispatch({
        type: POST_COMPANY_SIGNUP_SUCCESS,
        payload: data.result,
      });

      if (data.success == 'false') {
        dispatch(fetchError(data.result));
      } else {
        dispatch(showMessage('Signup successful '));
        setTimeout(() => {
          window.location.replace(`/signin`);
        }, 2000);
      }
    } catch (error) {
      dispatch({
        type: POST_COMPANY_SIGNUP_ERROR,
      });

      dispatch(fetchError());
    }
  };
};

export const PostClientUpdate = (Payload: any, handleClose: () => void) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: PUT_CLIENT_NOTES,
      });

      const {data} = await jwtAxios.put(
        `orders/order-contact-notes-update/`,
        Payload,
      );

      dispatch({
        type: PUT_CLIENT_NOTES_SUCCESS,
        payload: data.result,
      });
      dispatch(showMessage('Contact note added'));
      setTimeout(() => {
        handleClose();
      }, 2000);
    } catch (error) {
      dispatch({
        type: PUT_CLIENT_NOTES_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const PostNewTicket = (
  PostNewTicketPaylaod: any,
  handleClose: () => void,
  setRaiseTicketFormStatw: (param: any) => void,
) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: POST_NEW_TICKET,
      });

      const {data} = await jwtAxios.post(
        `/tickets/ticket-create/`,
        PostNewTicketPaylaod,
      );

      dispatch({
        type: POST_NEW_TICKET_SUCCESS,
        payload: data.result,
      });

      if (PostNewTicketPaylaod?.action == 'Download file') {
        if (data) {

          if(Array.isArray(data?.result)){
            let url =  data?.result[0][2];
            downloadAsset(url);
          }else{
            dispatch(fetchError(data?.result));
          }
         

         
          
        }
      } else {
        if (PostNewTicketPaylaod?.action == 'Delete file') {
          dispatch(showMessage('File Deleted '));
          setTimeout(() => {
            setRaiseTicketFormStatw({});
            window.location.reload()
          }, 2000);
        }

        setTimeout(() => {
          handleClose();
          setRaiseTicketFormStatw({});
        }, 2000);
      }
    } catch (error) {
      dispatch({
        type: POST_NEW_TICKET_ERROR,
      });
      dispatch(fetchError());
      setTimeout(() => {
        handleClose();
        setRaiseTicketFormStatw({});
      }, 2000);
    }
  };
};

export const PostTicketAction = (payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: POST_TICKET_ACTION,
      });

      const {data} = await jwtAxios.post(
        `scheduler/ticket-details-actions/`,
        payload,
      );

      dispatch({
        type: POST_TICKET_ACTION_SUCCESS,
        payload: data.result,
      });
      dispatch(showMessage('Response submitted'));
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      dispatch({
        type: POST_TICKET_ACTION_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const ApproveInvoiceAction = (payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: POST_APPROVE_INVOICE,
      });

      const {data} = await jwtAxios.post(
        `dashboard/beeinvoice-approve/`,
        payload,
      );

      dispatch({
        type: POST_APPROVE_INVOICE_SUCCESS,
        payload: data.result,
      });
      dispatch(showMessage(data.result ?? 'Invoice Approved'));
      setTimeout(() => {
        window.location.replace(`/dashboard/tasks`);
      }, 3000);
    } catch (error) {
      dispatch({
        type: POST_APPROVE_INVOICE_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const DeletInvoice = (payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: DELETE_INVOICE_ENTRY,
      });

      const {data} = await jwtAxios.delete(
        `dashboard/beeinvoice-entry-delete/?beeinvoiceentry_id=${payload?.entryId}&user_id=${payload?.user_id}`,
      );

      dispatch({
        type: DELETE_INVOICE_ENTRY_SUCCESS,
        payload: data.result,
      });
      dispatch(showMessage(data.result ?? 'Entry deleted'));
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      dispatch({
        type: DELETE_INVOICE_ENTRY_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const DeleteUser = (payload: any, push_url: string) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: DELETE_USER_REQ,
      });

      const {data} = await jwtAxios.delete(
        `accounts/delete-user/?user_id=${payload?.user_id}`,
      );

      dispatch({
        type: DELETE_USER_REQ_SUCCESS,
        payload: data.result,
      });
      dispatch(showMessage(data.result ?? 'Entry deleted'));
      setTimeout(() => {
        window.location.replace(push_url);
      }, 2000);
    } catch (error) {
      dispatch({
        type: DELETE_USER_REQ_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const PostInvoiceEntry = (payload: any, id: number) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: POST_NEW_INVOICE,
      });

      const {data} = await jwtAxios.post(
        `dashboard/beeinvoice-create/`,
        payload,
      );

      dispatch({
        type: POST_NEW_INVOICE_SUCCESS,
        payload: data.result,
      });
      dispatch(showMessage('Response submitted'));
      setTimeout(() => {
        window.location.replace(`/dashboard/bee-invoice-approve/${id}`);
      }, 2000);
    } catch (error) {
      dispatch({
        type: POST_NEW_INVOICE_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const UpdateEntry = (payload: any, invoiceId: number, id: number) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: UPDATE_INVOICE_ENTRY,
      });

      const {data} = await jwtAxios.put(
        `dashboard/beeinvoice-entry-update/?beeinvoiceentry_id=${invoiceId}`,
        payload,
      );

      dispatch({
        type: UPDATE_INVOICE_ENTRY_SUCCESS,
        payload: data.result,
      });
      dispatch(showMessage('Entry updated'));
      setTimeout(() => {
        window.location.replace(`/dashboard/bee-invoice-approve/${id}`);
      }, 2000);
    } catch (error) {
      dispatch({
        type: UPDATE_INVOICE_ENTRY_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const PostMarkPaidOrDue = (postMarkPaidOrDuePayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: POST_MARK_PAID_OR_DUE,
      });

      const {data} = await jwtAxios.post(
        `/dashboard/invoice-actions/?user_id=${postMarkPaidOrDuePayload.user_id}&invoice_id=${postMarkPaidOrDuePayload.invoice_id}`,
        postMarkPaidOrDuePayload,
      );

      dispatch({
        type: POST_MARK_PAID_OR_DUE_SUCCESS,
        payload: data.result,
      });
      dispatch(showMessage('Status Marked'));
    } catch (error) {
      dispatch({
        type: POST_MARK_PAID_OR_DUE_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const PostMarkPaidOrDueBeeInvoice = (postMarkPaidOrDuePayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: POST_MARK_PAID_OR_DUE,
      });

      const {data} = await jwtAxios.post(
        `/dashboard/bee-invoice-actions/?user_id=${postMarkPaidOrDuePayload.user_id}&invoice_id=${postMarkPaidOrDuePayload.invoice_id}`,
        postMarkPaidOrDuePayload,
      );

      dispatch({
        type: POST_MARK_PAID_OR_DUE_SUCCESS,
        payload: data.result,
      });
      dispatch(showMessage('Status Marked'));
    } catch (error) {
      dispatch({
        type: POST_MARK_PAID_OR_DUE_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetSageCSVDownload = (GetSageCSVDownloadPayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_SAGE_CSV_DOWNLOAD,
      });

      let url = `/dashboard/bee-invoice-sage-csv/?user_id=${GetSageCSVDownloadPayload.user_id}`;
      if (GetSageCSVDownloadPayload.user) {
        url += `&user=${GetSageCSVDownloadPayload.user}`;
      } else {
        url += `&user=`;
      }
      if (GetSageCSVDownloadPayload.start) {
        url += `&start=${GetSageCSVDownloadPayload.start}`;
      } else {
        url += `&start=`;
      }
      if (GetSageCSVDownloadPayload.end) {
        url += `&end=${GetSageCSVDownloadPayload.end}`;
      } else {
        url += `&end=`;
      }
      const {data} = await jwtAxios.get(url);

      dispatch({
        type: GET_SAGE_CSV_DOWNLOAD_SUCCESS,
        payload: data,
      });

      downloadFile(data, 'bee-invoice-sage');
    } catch (error) {
      dispatch({
        type: GET_SAGE_CSV_DOWNLOAD_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const pdfDownloadApi = (body: any) => {
  return async (dispatch: any) => {
    try {
      const {data} = await jwtAxios.get(
        `/dashboard/invoice-list/?user_id=${body?.user_id}&offset=0&action=download_pdf&invoice_id=${body?.invoice_id}`,
        {responseType: 'blob'},
      );

      downloadPDF(data, 'invoice');
    } catch (error) {
      dispatch(fetchError());
    }
  };
};
export const pdfDownloadBeeInvoiceApi = (body: any) => {
  return async (dispatch: any) => {
    try {
      const {data} = await jwtAxios.get(
        `/dashboard/bee-invoice-list/?user_id=${body?.user_id}&offset=0&action=download_pdf&bee_invoice_id=${body?.invoice_id}`,
        {responseType: 'blob'},
      );

      downloadPDF(data, 'invoice');
    } catch (error) {
      dispatch(fetchError());
    }
  };
};

export const PostAssetApproval = (PostAssetApprovalPaylaod: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: POST_ASSET_APPROVAL,
      });

      const {data} = await jwtAxios.post(
        `/scheduler/new-order-actions/`,
        PostAssetApprovalPaylaod,
      );

      dispatch({
        type: POST_ASSET_APPROVAL_SUCCESS,
        payload: data.result,
      });
      dispatch(showMessage('Task Approved !'));
      setTimeout(() => {
        window.location.replace(
          `/dashboard/orders/detail/${PostAssetApprovalPaylaod.reference}`,
        );
      }, 2000);
    } catch (error) {
      dispatch({
        type: POST_ASSET_APPROVAL_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const PostPendingAssetActionApproval = (
  PostAssetApprovalPaylaod: any,
  orderRef: string,
) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: POST_PENDING_ASSET,
      });

      const {data} = await jwtAxios.post(
        `scheduler/asset-approval-actions/`,
        PostAssetApprovalPaylaod,
      );

      dispatch({
        type: POST_PENDING_ASSET_SUCCESS,
        payload: data.result,
      });
      dispatch(showMessage('Task Approved !'));

      setTimeout(() => {
        window.location.replace(`/dashboard/orders/detail/${orderRef}`);
      }, 1500);
    } catch (error) {
      dispatch({
        type: POST_PENDING_ASSET_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const PostCreditRequest = (PostCreditRequest: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: POST_CREDIT_REQUEST,
      });

      const {data} = await jwtAxios.post(
        `dashboard/invoice/create-credit-note/`,
        PostCreditRequest,
      );

      dispatch({
        type: POST_CREDIT_REQUEST_SUCCESS,
        payload: data.result,
      });
      dispatch(showMessage('Credit note added'));
    } catch (error) {
      dispatch({
        type: POST_CREDIT_REQUEST_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const PostbeeNotes = (PostbeeNotes: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: POST_BEE_NOTES,
      });

      const {data} = await jwtAxios.post(`orders/bee-notes/`, PostbeeNotes);

      dispatch({
        type: POST_BEE_NOTES_SUCCESS,
        payload: data.result,
      });
      dispatch(showMessage('Bee note added'));
    } catch (error) {
      dispatch({
        type: POST_BEE_NOTES_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const PostSupportNotes = (PostSupportNotes: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: POST_SUPPORT_NOTES,
      });

      const {data} = await jwtAxios.post(
        `orders/support-notes/`,
        PostSupportNotes,
      );

      dispatch({
        type: POST_SUPPORT_NOTES_SUCCESS,
        payload: data.result,
      });
      dispatch(showMessage('Support note added'));
    } catch (error) {
      dispatch({
        type: POST_SUPPORT_NOTES_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const getPromocodeByCompany = (getPromocodeByCompanyPayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_COMPANY_PROMOCODE_LIST,
      });

      const {data} = await jwtAxios.get(
        `dashboard/company-promo-codes/?company_id=${getPromocodeByCompanyPayload?.id}`,
      );

      dispatch({
        type: GET_COMPANY_PROMOCODE_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_COMPANY_PROMOCODE_LIST_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const getPromocodeListing = (getPromocodeListingPayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_PROMOCODE_LIST,
      });

      const {data} = await jwtAxios.get(
        `dashboard/promo-codes-get/?user_id=${getPromocodeListingPayload?.userId}&offset=${getPromocodeListingPayload?.page}`,
      );

      dispatch({
        type: GET_PROMOCODE_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_PROMOCODE_LIST_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const PostPromoCode = (
  PostPromoCodePayload: any,
  historyFun: {push: (param: String) => void},
) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: POST_PROMOCODE,
      });

      const {data} = await jwtAxios.post(
        `dashboard/promo-codes-create/`,
        PostPromoCodePayload,
      );

      dispatch({
        type: POST_PROMOCODE_SUCCESS,
        payload: data.result,
      });
      dispatch(showMessage('Request added'));

      setTimeout(() => {
        historyFun?.push('/dashboard/promo-codes');
      }, 2000);
    } catch (error) {
      dispatch({
        type: POST_PROMOCODE_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const getPromocodeUserList = (getPromocodeUserList: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_PROMOCODE_USER_LIST,
      });

      const {data} = await jwtAxios.get(
        `dashboard/promo-code-user-list/?user_id=${getPromocodeUserList?.userId}`,
      );

      dispatch({
        type: GET_PROMOCODE_USER_LIST_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: GET_PROMOCODE_USER_LIST_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const getPromoCodeCompanyList = (getPromoCodeCompanyList: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_PROMO_CODE_COMPANY,
      });

      const {data} = await jwtAxios.get(
        `dashboard/promo-code-company-list/?user_id=${getPromoCodeCompanyList?.userId}`,
      );

      dispatch({
        type: GET_PROMO_CODE_COMPANY_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: GET_PROMO_CODE_COMPANY_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const UpdateRequest = (updatePayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: PUT_ORDER_REQUEST,
      });

      const {data} = await jwtAxios.put(
        `/orders/order-management/request/`,
        updatePayload,
      );

      dispatch({
        type: PUT_ORDER_REQUEST_SUCCESS,
        payload: data.result,
      });

      dispatch(showMessage('Request updated'));
      setTimeout(() => {
        dispatch(getRequestData(updatePayload?.request_id));
      }, 2000);
    } catch (error) {
      dispatch({
        type: PUT_ORDER_REQUEST_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const UpdateKeyPickup = (UpdateKeyPickupPayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: PUT_KEYPICKUP_REQUEST,
      });

      const {data} = await jwtAxios.put(
        `orders/order-management/key-pickup/`,
        UpdateKeyPickupPayload,
      );

      dispatch({
        type: PUT_KEYPICKUP_REQUEST_SUCCESS,
        payload: data.result,
      });

      dispatch(showMessage('KeyPickup updated'));
    } catch (error) {
      dispatch({
        type: PUT_KEYPICKUP_REQUEST_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const UpdateInvoice = (UpdateInvoicePayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: PUT_INVOICE_BEE_RATE_REQUEST,
      });

      const {data} = await jwtAxios.put(
        `orders/order-management/override-bee-rate/`,
        UpdateInvoicePayload,
      );

      dispatch({
        type: PUT_INVOICE_BEE_RATE_REQUEST_SUCCESS,
        payload: data.result,
      });

      if (typeof data.result !== 'string') {
        dispatch(showMessage('Bee Rate Updated'));
      } else {
        dispatch(showMessage(data.result));
      }
    } catch (error) {
      dispatch({
        type: PUT_INVOICE_BEE_RATE_REQUEST_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetInvoiceDetails = (GetInvoiceDetailsPayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_INVOICE_DETAILS,
      });

      const {data} = await jwtAxios.get(
        `dashboard/invoice-details/?user_id=${GetInvoiceDetailsPayload?.user_id}&invoice_id=${GetInvoiceDetailsPayload?.invoice_id}`,
      );

      dispatch({
        type: GET_INVOICE_DETAILS_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: GET_INVOICE_DETAILS_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetBeeInvoiceDetails = (GetBeeInvoiceDetailsPayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_BEE_INVOICE_DETAILS,
      });

      const {data} = await jwtAxios.get(
        `dashboard/bee-invoice-details/?user_id=${GetBeeInvoiceDetailsPayload?.user_id}&invoice_id=${GetBeeInvoiceDetailsPayload?.id}`,
      );

      dispatch({
        type: GET_BEE_INVOICE_DETAILS_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: GET_BEE_INVOICE_DETAILS_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const filterBillingInvoice = (filterInvoicePayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: BILLING_FILTER_INVOICE,
      });
      if (filterInvoicePayload?.ref) {
        var {data} = await jwtAxios.get(
          `dashboard/invoice-filter/?user_id=${filterInvoicePayload?.userId}&ref=${filterInvoicePayload?.ref}`,
        );
      } else {
        let ur = `dashboard/invoice-filter/?user_id=${filterInvoicePayload?.userId}&offset=${filterInvoicePayload.offset}`;
        if (filterInvoicePayload?.start) {
          ur += `&start=${filterInvoicePayload?.start}`;
        }
        if (filterInvoicePayload?.end) {
          ur += `&end=${filterInvoicePayload?.end}`;
        }
        var {data} = await jwtAxios.get(ur);
      }

      dispatch({
        type: BILLING_FILTER_INVOICE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: BILLING_FILTER_INVOICE_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const DownloadInvoice = (DownloadInvoicePayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: DOWNLOAD_BILLING_CSV,
      });

      let url = `dashboard/invoice-csv/?user_id=${DownloadInvoicePayload?.userId}&ref=${DownloadInvoicePayload?.ref}&export=${DownloadInvoicePayload.exportType}`;
      if (DownloadInvoicePayload?.start) {
        url += `&start=${DownloadInvoicePayload?.start}`;
      } else {
        url += `&start=`;
      }
      if (DownloadInvoicePayload?.end) {
        url += `&end=${DownloadInvoicePayload?.end}`;
      } else {
        url += `&end=`;
      }
      const {data} = await jwtAxios.get(url);

      dispatch({
        type: DOWNLOAD_BILLING_CSV_SUCCESS,
        payload: data.result,
      });

      downloadFile(
        data,
        `Invoice-${DownloadInvoicePayload?.start}-${DownloadInvoicePayload?.end}`,
      );
    } catch (error) {
      dispatch({
        type: DOWNLOAD_BILLING_CSV_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetBeeInvoice = (GetBeeInvoicePayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_BEE_INVOICE,
      });
      const {data} = await jwtAxios.get(
        `dashboard/bee-invoice-list/?user_id=${GetBeeInvoicePayload?.user_id}&offset=${GetBeeInvoicePayload?.page}`,
      );

      dispatch({
        type: GET_BEE_INVOICE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_BEE_INVOICE_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetFilteredInvoice = (GetFilteredInvoicePayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_BEE_FILTER_INVOICE,
      });

      let url = `dashboard/bee-invoice-filter/?user_id=${GetFilteredInvoicePayload?.userId}`;

      if (GetFilteredInvoicePayload.ref) {
        url += `&ref=${GetFilteredInvoicePayload.ref}`;
      } else {
        url += `&ref=`;
      }
      if (GetFilteredInvoicePayload.start) {
        url += `&start=${GetFilteredInvoicePayload.start}`;
      } else {
        url += `&start=`;
      }
      if (GetFilteredInvoicePayload.end) {
        url += `&end=${GetFilteredInvoicePayload.end}`;
      } else {
        url += `&end=`;
      }
      if (GetFilteredInvoicePayload.user) {
        url += `&user=${GetFilteredInvoicePayload.user}`;
      } else {
        url += `&user=`;
      }
      if (GetFilteredInvoicePayload.offset) {
        url += `&offset=${GetFilteredInvoicePayload.offset}`;
      } else {
        url += `&offset=0`;
      }
      var {data} = await jwtAxios.get(url);

      dispatch({
        type: GET_BEE_FILTER_INVOICE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_BEE_FILTER_INVOICE_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetCalendarMonthData = (Payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_CALENDAR_MONTH,
      });

      const {data} = await jwtAxios.get(
        `scheduler/tasks-calendar/month-view/?user_id=${Payload?.user_id}&month=${Payload?.month}&year=${Payload?.year}`,
      );

      dispatch({
        type: GET_CALENDAR_MONTH_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_CALENDAR_MONTH_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const DownloadInvoiceCSV = (DownloadInvoiceCSVPayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: DOWNLOAD_INVOICE_CSV,
      });
      let url = `dashboard/bee-invoice-csv/?user_id=${DownloadInvoiceCSVPayload?.userId}`;
      if (DownloadInvoiceCSVPayload.user) {
        url += `&user=${DownloadInvoiceCSVPayload.user}`;
      } else {
        url += `&user=`;
      }
      if (DownloadInvoiceCSVPayload.start) {
        url += `&start=${DownloadInvoiceCSVPayload.start}`;
      } else {
        url += `&start=`;
      }
      if (DownloadInvoiceCSVPayload.end) {
        url += `&end=${DownloadInvoiceCSVPayload.end}`;
      } else {
        url += `&end=`;
      }
      const {data} = await jwtAxios.get(url);

      dispatch({
        type: DOWNLOAD_INVOICE_CSV_SUCCESS,
        payload: data.result,
      });
      downloadFile(data, 'bee-invoice-csv');
    } catch (error) {
      dispatch({
        type: DOWNLOAD_INVOICE_CSV_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetDebtorsList = (GetDebtorsListPayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_DEBTOR_LIST,
      });

      let ur = `dashboard/backend/debtor-list/`;
      if (GetDebtorsListPayload?.overdue == true) {
        ur += `?overdue=on`;
      }
      const {data} = await jwtAxios.get(ur);

      dispatch({
        type: GET_DEBTOR_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_DEBTOR_LIST_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetDebtorsDetails = (GetDebtorsDetailsPayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_DEBTOR_DETAILS,
      });

      const {data} = await jwtAxios.get(
        `dashboard/backend/debtor-details/?id=${GetDebtorsDetailsPayload?.id}`,
      );

      dispatch({
        type: GET_DEBTOR_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_DEBTOR_DETAILS_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetDebtorCsv = (GetDebtorCsvPayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_DEBTOR_CSV,
      });

      const {data} = await jwtAxios.get(`dashboard/backend/debtor-csv/`);

      dispatch({
        type: GET_DEBTOR_CSV_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_DEBTOR_CSV_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetCreditorCsv = (GetCreditorPayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_CREDITOR_CSV,
      });
      const {data} = await jwtAxios.get(`dashboard/backend/creditor-csv/`);

      dispatch({
        type: GET_CREDITOR_CSV_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_CREDITOR_CSV_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetCreditorsList = (GetCreditorsListPayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_CREDITOR_LIST,
      });

      let ur = `dashboard/backend/creditor-list/`;
      if (GetCreditorsListPayload?.overdue == true) {
        ur += `?overdue=on`;
      }
      const {data} = await jwtAxios.get(ur);

      dispatch({
        type: GET_CREDITOR_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_CREDITOR_LIST_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetCreditorDetails = (GetCreditorDetailsPayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_CREDITOR_DETAILS,
      });

      const {data} = await jwtAxios.get(
        `dashboard/backend/creditor-details/?id=${GetCreditorDetailsPayload?.id}`,
      );

      dispatch({
        type: GET_CREDITOR_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_CREDITOR_DETAILS_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

interface IMarkPaidAction {
  param: string;
  apiBody: any;
  type: string;
  id: any;
}
export const MarkPaidAction = (MarkPaidAction: IMarkPaidAction) => {
  let {param, apiBody, type, id} = MarkPaidAction;
  return async (dispatch: any) => {
    try {
      dispatch({
        type: PUT_MARK_PAID,
      });

      const {data} = await jwtAxios.post(
        `dashboard/backend/${param}/`,
        apiBody,
      );

      dispatch({
        type: PUT_MARK_PAID_SUCCESS,
        payload: data,
      });

      dispatch(showMessage(data.result));

      setTimeout(() => {
        if (type === 'creditor') {
          let payload = {
            id: id,
          };
          dispatch(GetCreditorDetails(payload));
        } else {
          let payload = {
            id: id,
          };
          dispatch(GetDebtorsDetails(payload));
        }
      }, 2500);
    } catch (error) {
      dispatch({
        type: PUT_MARK_PAID_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetBackendActiveOrders = (page: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_ACTIVE_ORDER,
      });

      const {data} = await jwtAxios.get(
        `dashboard/backend/active-orders/?offset=${page}`,
      );

      dispatch({
        type: GET_ACTIVE_ORDER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_ACTIVE_ORDER_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetBackendMatterport = (GetBackendMatterportPayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_MATTERPORT_DETAILS,
      });

      if (GetBackendMatterportPayload?.companyid === 'all') {
        var {data} = await jwtAxios.get(`dashboard/backend/matterport-stats/`);
      } else {
        var {data} = await jwtAxios.get(
          `dashboard/backend/matterport-stats/?company_id=${GetBackendMatterportPayload?.companyid}`,
        );
      }

      dispatch({
        type: GET_MATTERPORT_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_MATTERPORT_DETAILS_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetBackendCompanyStats = (CompanyStatsPayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_BACKEND_COMPANY_STATS,
      });

      const {data} = await jwtAxios.get(
        `dashboard/backend/company-order-stats/?company_id=${CompanyStatsPayload.companyId}`,
      );

      dispatch({
        type: GET_BACKEND_COMPANY_STATS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_BACKEND_COMPANY_STATS_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetRegionCompanyStats = (Payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_REGIONAL_STATS,
      });

      const {data} = await jwtAxios.get(
        `dashboard/backend/region-order-stats/?region=${Payload.region}`,
      );

      dispatch({
        type: GET_REGIONAL_STATS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_REGIONAL_STATS_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetCountryStats = (Payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_BACKEND_COUNTRY_STATS,
      });

      const {data} = await jwtAxios.get(
        `dashboard/backend/country-order-stats/?country=${Payload.country}`,
      );

      dispatch({
        type: GET_BACKEND_COUNTRY_STATS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_BACKEND_COUNTRY_STATS_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetBackendOrderStats = (Payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_ORDER_STATS,
      });

      const {data} = await jwtAxios.get(`dashboard/backend/order-stats/ `);

      dispatch({
        type: GET_ORDER_STATS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_ORDER_STATS_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetBackendStripeData = (Payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_BACKEND_STRIPE,
      });

      const {data} = await jwtAxios.get(
        `dashboard/backend/stripe-data/?user_id=${Payload.user_id}&start=${Payload.start_date}&end=${Payload.end_date}`,
      );

      dispatch({
        type: GET_BACKEND_STRIPE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_BACKEND_STRIPE_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const DownloadStripeData = (Payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: DOWNLOAD_STRIPE_CSV,
      });

      const {data} = await jwtAxios.get(
        `dashboard/backend/stripe-data-csv/?user_id=${Payload.user_id}&start=${Payload.start_date}&end=${Payload.end_date}`,
      );

      dispatch({
        type: DOWNLOAD_STRIPE_CSV_SUCCESS,
        payload: data,
      });

      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Stripe_data.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      dispatch({
        type: DOWNLOAD_STRIPE_CSV_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const AddNewMovie = (Payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: POST_NEW_MOVIE,
      });

      const {data} = await jwtAxios.post(
        `dashboard/backend/movies/add-movie/`,
        Payload,
      );

      dispatch({
        type: POST_NEW_MOVIE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: POST_NEW_MOVIE_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const RestoreMovie = (Payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: POST_RESTORE_MOVIE,
      });

      const {data} = await jwtAxios.post(
        `dashboard/backedashboard/backend/movies/restore/`,
        Payload,
      );

      dispatch({
        type: POST_RESTORE_MOVIE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: POST_RESTORE_MOVIE_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const ArchiveMovie = (Payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: POST_ARCHIVE_MOVIE,
      });

      const {data} = await jwtAxios.post(
        `dashboard/backend/movies/archive/`,
        Payload,
      );

      dispatch({
        type: POST_ARCHIVE_MOVIE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: POST_ARCHIVE_MOVIE_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetCardlessEvent = (Payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_CARDLESS_EVENT_LIST,
      });

      const {data} = await jwtAxios.get(
        `dashboard/backend/cardless/event-list/?user_id=${Payload.user_id}&offset=${Payload.page}&query=${Payload.querry}`,
      );

      dispatch({
        type: GET_CARDLESS_EVENT_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_CARDLESS_EVENT_LIST_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetCardlessEventDetails = (Payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_CARDLESS_EVENT_DETAILS,
      });

      const {data} = await jwtAxios.get(
        `dashboard/backend/cardless/event-details/?user_id=${Payload.user_id}&offset=0&event_id=${Payload.event_id}`,
      );

      dispatch({
        type: GET_CARDLESS_EVENT_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_CARDLESS_EVENT_DETAILS_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetCardlessFialedDD = (Payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_FAILED_DD,
      });

      const {data} = await jwtAxios.get(
        `dashboard/backend/cardless/failed-invoice-list/?user_id=${Payload.user_id}&offset=${Payload.page}&query=${Payload.querry}`,
      );

      dispatch({
        type: GET_FAILED_DD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_FAILED_DD_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetCardlessFialedDD_Details = (Payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_FAILED_DD_DETAILS,
      });

      const {data} = await jwtAxios.get(
        `dashboard/backend/cardless/failed-invoice-details/?user_id=${Payload.USER_ID}&offset=0&failed_invoice_id=${Payload.Invoice_id}`,
      );

      dispatch({
        type: GET_FAILED_DD_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_FAILED_DD_DETAILS_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetCardlessMandate = (Payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_MANDATE_LIST,
      });

      const {data} = await jwtAxios.get(
        `dashboard/backend/cardless/mandate-list/?user_id=${Payload.user_id}&offset=${Payload.page}&query=${Payload.querry}`,
      );

      dispatch({
        type: GET_MANDATE_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_MANDATE_LIST_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetCardlessMandateDetails = (Payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_MENDATE_DETAILS,
      });

      const {data} = await jwtAxios.get(
        `dashboard/backend/cardless/mandate-details/?user_id=${Payload?.user_id}&offset=0&mandate_id=${Payload?.mandate_id}`,
      );

      dispatch({
        type: GET_MENDATE_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_MENDATE_DETAILS_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetCardlessPaymentDetails = (Payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_PAYMENT_DETAILS,
      });

      const {data} = await jwtAxios.get(
        `dashboard/backend/cardless/payment-details/?user_id=${Payload?.user_id}&offset=0&payment_id=${Payload?.payment_id}`,
      );

      dispatch({
        type: GET_PAYMENT_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_PAYMENT_DETAILS_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetRedirectFlow = (Payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_REDIRECT_FLOWS,
      });

      const {data} = await jwtAxios.get(
        `dashboard/backend/cardless/redirect-flow-list/?user_id=${Payload.user_id}&offset=${Payload.page}&query=${Payload.querry}`,
      );

      dispatch({
        type: GET_REDIRECT_FLOWS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_REDIRECT_FLOWS_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetRedirectFlowDetails = (Payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_REDIRECT_FLOW_DETAILS,
      });

      const {data} = await jwtAxios.get(
        `dashboard/backend/cardless/redirect-flow-details/?user_id=${Payload.user_id}&offset=0&redirect_flow_id=${Payload.flow_id}`,
      );

      dispatch({
        type: GET_REDIRECT_FLOW_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_REDIRECT_FLOW_DETAILS_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetPaymentDetails = (Payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_PAY_DETAILS,
      });

      const {data} = await jwtAxios.get(
        `stripe/one-time-payment-details/?code=${Payload.code}`,
      );

      dispatch({
        type: GET_PAY_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_PAY_DETAILS_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetCardlessPaymentList = (Payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_CARDLESS_PAYMENT,
      });

      const {data} = await jwtAxios.get(
        `dashboard/backend/cardless/payment-list/?user_id=${Payload.user_id}&offset=0`,
      );

      dispatch({
        type: GET_CARDLESS_PAYMENT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_CARDLESS_PAYMENT_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetMonthlyAvailability = (Payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_MONTHLY_AVAILABILITY,
      });

      const {data} = await jwtAxios.get(
        `scheduler/month/availability/?user_id=${Payload.user_id}&month=${Payload.month}&year=${Payload.year}`,
      );

      dispatch({
        type: GET_MONTHLY_AVAILABILITY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_MONTHLY_AVAILABILITY_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetWeeklyAvailability = (Payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_WEEKLY_AVAILABILITY,
      });

      const {data} = await jwtAxios.get(
        `scheduler/week/availability/?year=${Payload.year}&month=${Payload.month}&day=${Payload.day}&city=${Payload.city}&distance=${Payload.distance}`,
      );

      dispatch({
        type: GET_WEEKLY_AVAILABILITY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_WEEKLY_AVAILABILITY_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetDailyAvailability = (Payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_DAILY_AVAILABILITY,
      });

      const {data} = await jwtAxios.get(
        Payload.distance && Payload.city
          ? `scheduler/day/availability/?year=${Payload.year}&month=${Payload.month}&day=${Payload.day}&city=${Payload.city}&distance=${Payload.distance}&bee_level=${Payload.bee_level}&slot=${Payload.slot}`
          : `scheduler/day/availability/?year=${Payload.year}&month=${Payload.month}&day=${Payload.day}&city=${Payload.city}`,
      );

      dispatch({
        type: GET_DAILY_AVAILABILITY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_DAILY_AVAILABILITY_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetDateRange = (Payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_DATE_RANGE,
      });

      const {data} = await jwtAxios.get(
        `scheduler/date-range/availability/?start_date=${Payload?.start_date}&end_date=${Payload?.end_date}&city=${Payload?.city}&user_id=${Payload.userId}&distance=${Payload?.distance}`,
      );

      dispatch({
        type: GET_DATE_RANGE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_DATE_RANGE_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const UpdateOrderSpecialRequest = (
  UpdateOrderSpecialRequestPayload: any,
) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: PUT_ORDER_SPECIAL_REQUEST,
      });

      const {data} = await jwtAxios.put(
        `dashboard/special-request-update/?special_request_id=${UpdateOrderSpecialRequestPayload.requestId}`,
        UpdateOrderSpecialRequestPayload.body,
      );

      dispatch({
        type: PUT_ORDER_SPECIAL_REQUEST_SUCCESS,
        payload: [data.result],
      });

      dispatch(showMessage('Request updated'));
    } catch (error) {
      dispatch({
        type: PUT_ORDER_SPECIAL_REQUEST_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const getProfileDetails = (Payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_PROFILE_DETAILS,
      });

      const {data} = await jwtAxios.get(
        `dashboard/backend/profile-details/?user_id=${Payload?.user_id}`,
      );

      dispatch({
        type: GET_PROFILE_DETAILS_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: GET_PROFILE_DETAILS_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const postProfileDetails = (payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_PROFILE_DETAILS,
      });

      const {data} = await jwtAxios.post(
        `dashboard/backend/profile-details/`,
        payload,
      );

      dispatch({
        type: GET_PROFILE_DETAILS_SUCCESS,
        payload: data.result,
      });

      dispatch(showMessage('Bee Detail updated'));
    } catch (error) {
      dispatch({
        type: GET_PROFILE_DETAILS_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const postAmendInvoice = (payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: POST_AMEND_INVOICE,
      });

      const {data} = await jwtAxios.post(
        `orders/order-management/invoice-amend/`,
        payload,
      );

      dispatch({
        type: POST_AMEND_INVOICE_SUCCESS,
        payload: data.result,
      });

      dispatch(showMessage('Amend Invoice updated'));
    } catch (error) {
      dispatch({
        type: POST_AMEND_INVOICE_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const BulkUploadSpecialRequest = (
  BulkUploadSpecialRequestPayload: any,
) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: PUT_BULK_REQUEST,
      });

      const {data} = await jwtAxios.put(
        `dashboard/special-request-bulk-update/`,
        BulkUploadSpecialRequestPayload,
      );

      dispatch({
        type: PUT_BULK_REQUEST_SUCCESS,
        payload: [data.result],
      });

      dispatch(showMessage('Request updated'));
    } catch (error) {
      dispatch({
        type: PUT_BULK_REQUEST_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const AddAmmendInvoice = (UpdateAmmendInvoicePayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: POST_INVOICE_AMMEND,
      });

      const {data} = await jwtAxios.post(
        `orders/order-management/invoice-amend/`,
        UpdateAmmendInvoicePayload,
      );

      dispatch({
        type: POST_INVOICE_AMMEND_SUCCESS,
        payload: data.result,
      });

      dispatch(showMessage(` ${data.result ?? 'Invoice Ammended'} `));
    } catch (error) {
      dispatch({
        type: POST_INVOICE_AMMEND_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const OrderAssetsActions = (payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: POST_ORDER_ACTIONS,
      });

      const {data} = await jwtAxios.post(
        `dashboard/order-assets-actions/`,
        payload,
      );

      dispatch({
        type: POST_ORDER_ACTIONS_SUCCESS,
        payload: data.result,
      });

      if (payload.action != 'download_movie') {
        dispatch(showMessage(`${payload.action}  ${' '} updated `));
      } else {
        const url = data?.result?.movie_download_url;
        downloadAsset(url);
      }
    } catch (error) {
      dispatch({
        type: POST_ORDER_ACTIONS_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const ApproveAssetGroup = (payload: any, activeIds: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: POST_APPROVE_ASSET_GROUP,
        payload: activeIds,
      });

      let temp = {
        ...activeIds,
        currentId: null,
      };
      await jwtAxios.post(`orders/approve-asset-group/`, payload);

      dispatch({
        type: POST_APPROVE_ASSET_GROUP_SUCCESS,
        payload: temp,
      });
    } catch (error) {
      dispatch({
        type: POST_APPROVE_ASSET_GROUP_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const UpdatePackage = (UpdatePackagepayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: UPDATE_PACKAGE_ADDON,
      });

      const {data} = await jwtAxios.post(
        `orders/order-upgrade/`,
        UpdatePackagepayload,
      );

      dispatch({
        type: UPDATE_PACKAGE_ADDON_SUCCESS,
        payload: data.result,
      });

      dispatch(showMessage('Package updated'));
    } catch (error) {
      dispatch({
        type: UPDATE_PACKAGE_ADDON_ERROR,
      });
      dispatch(fetchError());
    }
  };
};
export const UpdateAddonPackage = (UpdatePackagepayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: UPDATE_PACKAGE_ADDON,
      });

      const {data} = await jwtAxios.post(
        `products/upgraded-addons-package/`,
        UpdatePackagepayload,
      );

      dispatch({
        type: UPDATE_PACKAGE_ADDON_SUCCESS,
        payload: data.result,
      });

      dispatch(showMessage('Package updated'));
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      dispatch({
        type: UPDATE_PACKAGE_ADDON_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const holdOrderAction = (holdOrderPayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: HOLD_ORDER_REQUEST,
      });
       
      const {data} = await jwtAxios.put(
        `orders/order-management/hold/`,
        holdOrderPayload,
      );

      dispatch({
        type: HOLD_ORDER_REQUEST_SUCCESS,
        payload: data.result,
      });

      dispatch(showMessage(data.result));

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      dispatch({
        type: HOLD_ORDER_REQUEST_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const putMovieDetail = (putMovieDetailPayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: PUT_MOVIE_DETAIL,
      });

      const {data} = await jwtAxios.post(
        `dashboard/backend/movies/update-movie/`,
        putMovieDetailPayload,
      );

      dispatch({
        type: PUT_MOVIE_DETAIL_SUCCESS,
        payload: data.result,
      });

      dispatch(showMessage('Movie Updated'));
    } catch (error) {
      dispatch({
        type: PUT_MOVIE_DETAIL_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const PutOrderCompletion = (OrderPayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: PUT_ORDER_COMPLETION,
      });

      const {data} = await jwtAxios.post(
        `orders/order-management/completions-check/`,
        OrderPayload,
      );

      dispatch({
        type: PUT_ORDER_COMPLETION_SUCCESS,
        payload: data.result,
      });

      dispatch(showMessage(data.result));

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      dispatch({
        type: PUT_ORDER_COMPLETION_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const DeleteOrder = (DeleteOrder: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: DELETE_ORDER,
      });

      const {data} = await jwtAxios.delete(
        `orders/order-delete/?ref=${DeleteOrder?.ref}`,
      );

      dispatch({
        type: DELETE_ORDER_SUCCESS,
        payload: data.result,
      });

      dispatch(showMessage(data.result));
    } catch (error) {
      dispatch({
        type: DELETE_ORDER_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const ResumeOrderAction = (ResumeOrderpayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: RESUME_ORDER_REQUEST,
      });

      const {data} = await jwtAxios.put(
        `orders/order-management/resume/`,
        ResumeOrderpayload,
      );

      dispatch({
        type: RESUME_ORDER_REQUEST_SUCCESS,
        payload: data.result,
      });

      dispatch(showMessage(data.result));

      setTimeout(() => {
        dispatch(
          getOrderActionButtons({
            ref: ResumeOrderpayload?.ref,
            userId: ResumeOrderpayload?.userId,
          }),
        );
      }, 2000);
    } catch (error) {
      dispatch({
        type: RESUME_ORDER_REQUEST_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const CancelAction = (CancelActionpayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: CANCEL_ORDER_REQUEST,
      });

      const {data} = await jwtAxios.put(
        `orders/order-management/cancel/`,
        CancelActionpayload,
      );

      dispatch({
        type: CANCEL_ORDER_REQUEST_SUCCESS,
        payload: data.result,
      });

      dispatch(showMessage(data.result));
    } catch (error) {
      dispatch({
        type: CANCEL_ORDER_REQUEST_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const RenewAction = (RenewActionpayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: PUT_ORDER_RENEW_REQUEST,
      });

      const {data} = await jwtAxios.post(
        `dashboard/renew-hosting-task/`,
        RenewActionpayload,
      );

      dispatch({
        type: PUT_ORDER_RENEW_REQUEST_SUCCESS,
        payload: data.result,
      });

      dispatch(showMessage(data.result));
    } catch (error) {
      dispatch({
        type: PUT_ORDER_RENEW_REQUEST_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const CancelHosting = (CancelHostingpayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: PUT_CANCEL_HOISTING,
      });

      const {data} = await jwtAxios.put(
        `orders/order-management/cancel-hosting/`,
        CancelHostingpayload,
      );

      dispatch({
        type: PUT_CANCEL_HOISTING_SUCCESS,
        payload: data.result,
      });

      dispatch(showMessage(data.result));
    } catch (error) {
      dispatch({
        type: PUT_CANCEL_HOISTING_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const RegenerateOrder = (RegenerateOrderpayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: PUT_REGENERATE_ORDER,
      });

      const {data} = await jwtAxios.post(
        `orders/order-management/movie-regenerate/`,
        RegenerateOrderpayload,
      );

      dispatch({
        type: PUT_REGENERATE_ORDER_SUCCESS,
        payload: data.result,
      });

      dispatch(showMessage(data.result));
    } catch (error) {
      dispatch({
        type: PUT_REGENERATE_ORDER_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const YoutubeUpload = (Payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: PUT_YOUTUBE_UPLOAD,
      });

      const {data} = await jwtAxios.post(`orders/youtube-upload/`, Payload);

      dispatch({
        type: PUT_YOUTUBE_UPLOAD_SUCCESS,
        payload: data.result,
      });

      dispatch(showMessage(data.result));
    } catch (error) {
      dispatch({
        type: PUT_YOUTUBE_UPLOAD_ERROR,
      });

      dispatch(fetchError());
    }
  };
};

export const CompanyInvoice = (Payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: COMPANY_ACTION,
      });

      const {data} = await jwtAxios.post(
        `dashboard/company-invoice-approve-actions/`,
        Payload,
      );

      dispatch({
        type: COMPANY_ACTION_SUCCESS,
        payload: data.result,
      });

      dispatch(showMessage(data.result));
      setTimeout(() => {
        if (Payload?.action === 'companyinvoice_entry_delete') {
          dispatch(getTaskDetails({id: Payload?.task_id}));
        }
        if (
          Payload?.action === 'companyinvoice_address_change' ||
          Payload?.action === 'companyinvoice_entry_create' ||
          Payload?.action === 'companyinvoice_entry_update'
        ) {
          window.location.replace(
            `/dashboard/company-invoice-approve/${Payload?.task_id}/`,
          );
        }

        if (Payload?.action === 'companyinvoice_approve') {
          window.location.replace(`/dashboard/tasks`);
        }
      }, 2000);
    } catch (error) {
      dispatch({
        type: COMPANY_ACTION_ERROR,
      });

      dispatch(fetchError());
    }
  };
};

export const ApprovePayment = (Payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: APPROVE_INVOICE,
      });

      const {data} = await jwtAxios.post(
        `dashboard/approve-payment-actions/`,
        Payload,
      );

      dispatch({
        type: APPROVE_INVOICE_SUCCESS,
        payload: data.result,
      });

      dispatch(showMessage(data.result));
      setTimeout(() => {
        if (Payload?.action === 'approve') {
          window.location.replace(`/dashboard/tasks`);
        }
      }, 2000);
    } catch (error) {
      dispatch({
        type: APPROVE_INVOICE_ERROR,
      });

      dispatch(fetchError());
    }
  };
};

export const AllocateShooter = (AllocateShooterPayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: POST_SHOOTER_ALLOCATION,
      });

      const {data} = await jwtAxios.post(
        `other/shooter-allocation/`,
        AllocateShooterPayload,
      );

      dispatch({
        type: POST_SHOOTER_ALLOCATION_SUCCESS,
        payload: data.result,
      });

      dispatch(showMessage(data.result));

      setTimeout(() => {
        dispatch(
          getOrderManagementTables(AllocateShooterPayload?.order_reference),
        );
      }, 2000);
    } catch (error) {
      dispatch({
        type: POST_SHOOTER_ALLOCATION_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const ShooterAvailability = (ShooterAvailabilityPayload: any,clearPrevious:boolean) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: POST_SHOOTER_AVAILABILITY,
      });

      const {data} = await jwtAxios.post(
        `other/shooters-availability/`,
        ShooterAvailabilityPayload,
      );
    
      
      dispatch({
        type: POST_SHOOTER_AVAILABILITY_SUCCESS,
        payload: {dataToAdd:data.result,clearPrevious},
      });
    } catch (error) {
      dispatch({
        type: POST_SHOOTER_AVAILABILITY_ERROR,
      });
      dispatch(fetchError());
    }
  };
};
export const AllocateBestBees = (AllocateBestBeesPayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: POST_BEST_BEES,
      });

      const {data} = await jwtAxios.post(
        `other/best-bees/`,
        AllocateBestBeesPayload,
      );

      dispatch({
        type: POST_BEST_BEES_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: POST_BEST_BEES_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const CreateSpecialCharge = (CreateSpeacialChargePayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: POST_SPECIAL_CHARGE,
      });

      const {data} = await jwtAxios.post(
        `orders/order-management/create-special-charge/`,
        CreateSpeacialChargePayload,
      );

      dispatch({
        type: POST_SPECIAL_CHARGE_SUCCESS,
        payload: data.result,
      });

      dispatch(showMessage('Special charge added'));
      dispatch(
        getOrderManagementTables(CreateSpeacialChargePayload?.order_reference),
      );
    } catch (error) {
      dispatch({
        type: POST_SPECIAL_CHARGE_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const CompanyInvitation = (Payload: any, hidetoast?: boolean) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: POST_COMPANY_INVITATION,
      });

      const {data} = await jwtAxios.post(
        `accounts/company_signup_with_invitation/`,
        Payload,
      );

      localStorage.removeItem('REGISTRATION_BODY');
      dispatch({
        type: POST_COMPANY_INVITATION_SUCCESS,
        payload: data.result,
      });

      if (data?.result?.success == 'true') {
        if (!hidetoast) {
          dispatch(showMessage(data?.result?.result));
        }
      } else {
        dispatch(fetchError(data?.result?.result));
      }
    } catch (error) {
      dispatch({
        type: POST_COMPANY_INVITATION_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const CreateBeeSpecialCharge = (CreateBeeSpeacialCharge: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: POST_BEE_SPECIAL_CHARGE,
      });

      const {data} = await jwtAxios.post(
        `orders/order-management/create-bee-special-charge/`,
        CreateBeeSpeacialCharge,
      );

      dispatch({
        type: POST_BEE_SPECIAL_CHARGE_SUCCESS,
        payload: data.result,
      });

      dispatch(showMessage('Charge added '));
      dispatch(
        getOrderManagementTables(CreateBeeSpeacialCharge?.order_reference),
      );
    } catch (error) {
      dispatch({
        type: POST_BEE_SPECIAL_CHARGE_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const PostRegenerateAssetGroup = (
  PostRegenerateAssetGroupPayload: any,
) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: POST_REGENERATE_ASSET_GROUP,
      });

      const {data} = await jwtAxios.post(
        `orders/regenerate-asset-group/`,
        PostRegenerateAssetGroupPayload,
      );

      dispatch({
        type: POST_REGENERATE_ASSET_GROUP_SUCCESS,
        payload: data.result,
      });

      dispatch(showMessage(data.result));
    } catch (error) {
      dispatch({
        type: POST_REGENERATE_ASSET_GROUP_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const DeleteAssetGroup = (DeleteAssetGroupPayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: DELETE_ASSET_GROUP,
      });

      const {data} = await jwtAxios.post(
        `orders/delete-all-assets/`,
        DeleteAssetGroupPayload,
      );

      dispatch({
        type: DELETE_ASSET_GROUP_SUCCESS,
        payload: data.result,
      });

      dispatch(showMessage(data.result));
    } catch (error) {
      dispatch({
        type: DELETE_ASSET_GROUP_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const SetAssetApproval = (SetAssetApprovalPayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: POST_SET_ASSET_APPROVAL,
      });

      const {data} = await jwtAxios.post(
        `orders/set-approval/`,
        SetAssetApprovalPayload,
      );

      dispatch({
        type: POST_SET_ASSET_APPROVAL_SUCCESS,
        payload: data.result,
      });

      dispatch(showMessage(data.result));
    } catch (error) {
      dispatch({
        type: POST_SET_ASSET_APPROVAL_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const PostZipFileAssetGroup = (
  PostZipFileAssetGroupPayload: any,
  DownloadZip?: Boolean,
) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: POST_ZIP_ASSET_GROUP,
        payload: PostZipFileAssetGroupPayload?.asset_group_id,
      });

      const {data} = await jwtAxios.post(
        `orders/create-zip-file/`,
        PostZipFileAssetGroupPayload,
      );

      dispatch({
        type: POST_ZIP_ASSET_GROUP_SUCCESS,
        payload: data.result,
      });

      if (DownloadZip) {
        downloadZIPFile(data.result?.download_link);
      } else {
        dispatch(showMessage('Zip file regenerated'));
      }
    } catch (error) {
      dispatch({
        type: POST_ZIP_ASSET_GROUP_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetManualBees = (GetManualBees: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_MANUAL_BEES,
      });

      const {data} = await jwtAxios.get(
        `other/shooter-list/?ref=${
          GetManualBees?.ref
        }&addon_slug=${GetManualBees?.slug.toLowerCase()}&distance=${
          GetManualBees?.distance
        }&slot=${GetManualBees?.slot}&date=${GetManualBees?.date}&user_id=${
          GetManualBees?.userId
        }`,
      );

      dispatch({
        type: GET_MANUAL_BEES_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: GET_MANUAL_BEES_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetCompanyLinkStatus = (Payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_COMPANY_LINK_STATUS,
      });

      const {data} = await jwtAxios.get(
        `accounts/get-company-link-status/?company_id=${Payload?.id}`,
      );

      dispatch({
        type: GET_COMPANY_LINK_STATUS_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: GET_COMPANY_LINK_STATUS_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetGroupManualBees = (GetGroupManualBees: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_GROUP_MANUAL_BEES,
      });

      const {data} = await jwtAxios.get(
        `other/shooter-list/?ref=${
          GetGroupManualBees?.ref
        }&addon_slug=${GetGroupManualBees?.slug.toLowerCase()}&distance=${
          GetGroupManualBees?.distance
        }&slot=${GetGroupManualBees?.slot}&date=${
          GetGroupManualBees?.date
        }&user_id=${GetGroupManualBees?.userId}`,
      );

      let ApiBody = {
        slug: GetGroupManualBees?.slug.toLowerCase(),
        data: data.result,
      };
      dispatch({
        type: GET_GROUP_MANUAL_BEES_SUCCESS,
        payload: ApiBody,
      });
    } catch (error) {
      dispatch({
        type: GET_GROUP_MANUAL_BEES_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetCompanyDetails = (GetComapnydetails: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_COMPANY_DETAILS,
      });

      const {data} = await jwtAxios.get(
        `organisations/company-location-details/?company_id=${GetComapnydetails?.company_id}&user_id=${GetComapnydetails?.userId}`,
      );
       
      dispatch({
        type: GET_COMPANY_DETAILS_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: GET_COMPANY_DETAILS_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const postCompanyDetails = (postCompanyDetailsPayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: POST_COMPANY_DETAILS,
      });

      const {data} = await jwtAxios.post(
        `dashboard/backend/company-details/`,
        postCompanyDetailsPayload,
      );

      dispatch({
        type: POST_COMPANY_DETAILS_SUCCESS,
        payload: data.result,
      });
      dispatch(showMessage('Company Details Saved'));
    } catch (error) {
      dispatch({
        type: POST_COMPANY_DETAILS_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetHostingPeriod = (GetHostingPeriod: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_HOSTING_PERIOD,
      });

      const {data} = await jwtAxios.get(
        `orders/get-hosting-period/?ref=${GetHostingPeriod.ref}`,
      );

      dispatch({
        type: GET_HOSTING_PERIOD_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: GET_HOSTING_PERIOD_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetCompanyStats = (GetHostingPeriod: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_COMPANY_STATS,
      });

      const {data} = await jwtAxios.get(`dashboard/backend/order-stats/`);

      dispatch({
        type: GET_COMPANY_STATS_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: GET_COMPANY_STATS_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const AssetUploaderSuccess = (AssetUploaderSuccessPayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: POST_ASSET_REQUEST,
      });

      const {data} = await jwtAxios.post(
        `other/asset-uploader-success/`,
        AssetUploaderSuccessPayload?.body,
      );

      dispatch({
        type: POST_ASSET_REQUEST_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: POST_ASSET_REQUEST_ERROR,
      });
      dispatch(fetchError(`Failed to upload ${AssetUploaderSuccessPayload?.body.name}`));
    }
  };
};

export const DeleteOrderSpecialRequest = (
  DeleteOrderSpecialRequestPayload: any,
) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: DELETE_ORDER_SPECIAL_REQUEST,
      });

      const {data} = await jwtAxios.delete(
        `dashboard/special-request-delete/?special_request_id=${DeleteOrderSpecialRequestPayload?.id}`,
      );

      dispatch({
        type: DELETE_ORDER_SPECIAL_REQUEST_SUCCESS,
        payload: data.result,
      });

      dispatch(showMessage('Special request deleted'));
    } catch (error) {
      dispatch({
        type: DELETE_ORDER_SPECIAL_REQUEST_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const DeleteCompany = (Payload: any, goBack?: any, info: string) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: DELETE_COMPANY,
      });

      const {data} = await jwtAxios.delete(
        `accounts/delete-company/?company_id=${Payload?.id}`,
      );

      dispatch({
        type: DELETE_COMPANY_SUCCESS,
        payload: data.result,
      });

      if (info) {
        dispatch(showWarning(info ?? 'Company deleted'));
      } else {
        dispatch(showMessage('Company deleted'));
      }

      if (goBack) {
        setTimeout(() => {
          goBack();

          let ApiBody = {
            userId: Payload.userId,
            offset: 0,
            query: '',
          };
          dispatch(GetCompanyListNew(ApiBody));
        }, 2000);
      } else {
        setTimeout(() => {
          window.location.replace('/signin');
        }, 2000);
      }
    } catch (error) {
      dispatch({
        type: DELETE_COMPANY_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const DeleteOrderTicket = (DeleteOrderTicketPayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: DELETE_ORDER_TICKET,
      });

      const {data} = await jwtAxios.delete(
        `dashboard/ticket-delete/?ticket_id=${DeleteOrderTicketPayload?.id}`,
      );

      dispatch({
        type: DELETE_ORDER_TICKET_SUCCESS,
        payload: data.result,
      });

      dispatch(showMessage('Ticket deleted'));
    } catch (error) {
      dispatch({
        type: DELETE_ORDER_TICKET_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetInformationPage = (GetInformation: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_INFORMATION_PAGE,
      });

      const {data} = await jwtAxios.get(
        `dashboard/backend/info-page/?user_id=${GetInformation?.userId}`,
      );

      dispatch({
        type: GET_INFORMATION_PAGE_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: GET_INFORMATION_PAGE_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetClientListAction = (ClientList: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_CLIENT_LIST,
      });

      const {data} = await jwtAxios.get(
        ClientList?.query
          ? `dashboard/backend/clients-list/?offset=${ClientList?.offset}&user_id=${ClientList?.userId}&query=${ClientList.query}`
          : `dashboard/backend/clients-list/?offset=${ClientList?.offset}&user_id=${ClientList?.userId}`,
      );

      dispatch({
        type: GET_CLIENT_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_CLIENT_LIST_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetCompanyListNew = (companyList: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_COMPANY_LIST_NEW,
      });

      const {data} = await jwtAxios.get(
        `dashboard/backend/company-list/?offset=${companyList?.offset}&user_id=${companyList?.userId}&query=${companyList?.query}`,
      );

      dispatch({
        type: GET_COMPANY_LIST_NEW_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_COMPANY_LIST_NEW_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetCompanyListAll = (companyList: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_COMPANY_LIST_ALL,
      });

      const {data} = await jwtAxios.get(
        `dashboard/backend/all-company-list/?user_id=${companyList?.userId}`,
      );

      dispatch({
        type: GET_COMPANY_LIST_ALL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_COMPANY_LIST_ALL_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetPrefernces = () => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_PREFERENCES,
      });

      const {data} = await jwtAxios.get(`accounts/get-preference-list/`);

      dispatch({
        type: GET_PREFERENCES_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_PREFERENCES_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetMusic = () => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_MUSIC_REQ,
      });

      const {data} = await jwtAxios.get(`accounts/get-music-list/`);

      dispatch({
        type: GET_MUSIC_REQ_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_MUSIC_REQ_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetUserType = () => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_USER_TYPE,
      });

      const {data} = await jwtAxios.get(`/accounts/get-usertype-list/`);

      dispatch({
        type: GET_USER_TYPE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_USER_TYPE_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetPermission = () => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_PERMISSION_REQ,
      });

      const {data} = await jwtAxios.get(`accounts/get-permission-list/ `);

      dispatch({
        type: GET_PERMISSION_REQ_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_PERMISSION_REQ_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetGroupList = () => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_GROUPS_REQ,
      });

      const {data} = await jwtAxios.get(`accounts/get-group-list/`);

      dispatch({
        type: GET_GROUPS_REQ_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_GROUPS_REQ_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetCountryList = () => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_COUNTRY_LIST,
      });

      const {data} = await jwtAxios.get(`accounts/get-country-list/`);

      const formatData = (data) => {
        const formattedArray = [];

        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            const country = data[key];
            country.code = key;
            formattedArray.push(country);
          }
        }

        return formattedArray;
      };

      dispatch({
        type: GET_COUNTRY_LIST_SUCCESS,
        payload: formatData(data?.result),
      });
    } catch (error) {
      dispatch({
        type: GET_COUNTRY_LIST_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetSlots = () => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_SLOTS_REQ,
      });

      const {data} = await jwtAxios.get(`accounts/get-slot-list/`);

      dispatch({
        type: GET_SLOTS_REQ_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_SLOTS_REQ_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetBeeKitList = () => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_BEE_KIT_LIST,
      });

      const {data} = await jwtAxios.get(`accounts/get-beekit-list/`);

      dispatch({
        type: GET_BEE_KIT_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_BEE_KIT_LIST_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetTotalOrderPrice = (Payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_ORDER_TOTAL_PRICE,
      });

      const {data} = await jwtAxios.get(
        `orders/order-total-price/?user_id=${Payload?.user_id}&package_id=${Payload?.package_id}`,
      );

      dispatch({
        type: GET_ORDER_TOTAL_PRICE_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: GET_ORDER_TOTAL_PRICE_ERROR,
      });

      dispatch(fetchError());
    }
  };
};

export const PostbackendReports = (payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: POST_ALL_REPORTS,
      });

      const {data} = await jwtAxios.post(`dashboard/backend/reports/`, payload);

      dispatch({
        type: POST_ALL_REPORTS_SUCCESS,
        payload: data,
      });

      downloadFile(data, `${payload.report_type}`);
    } catch (error) {
      dispatch({
        type: POST_ALL_REPORTS_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const UpdateClientUser = (payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: PUT_CLIENT_UPDATE,
      });

      const {data} = await jwtAxios.put(
        `accounts/update-client-user/`,
        payload,
      );

      dispatch({
        type: PUT_CLIENT_UPDATE_SUCCESS,
        payload: data,
      });

      if (data?.result?.success === 'true') {
        dispatch(showMessage(data?.result?.result));
        setTimeout(() => {
          window.location.replace(
            '/dashboard/backend/report/management/client/',
          );
        }, 2000);
      } else {
        dispatch(showMessage(data?.result));
      }
    } catch (error) {
      dispatch({
        type: PUT_CLIENT_UPDATE_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const AddNewClient = (payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: ADD_CLIENT_REQ,
      });

      const {data} = await jwtAxios.post(
        `accounts/create-client-user/`,
        payload,
      );

      dispatch({
        type: ADD_CLIENT_REQ_SUCCESS,
        payload: data,
      });

      if (data?.result?.success === 'true') {
        dispatch(showMessage('Client Added !'));
        setTimeout(() => {
          window.location.replace(
            '/dashboard/backend/report/management/client/',
          );
        }, 2000);
      } else {
        dispatch(fetchError(data?.result?.result));
      }
    } catch (error) {
      dispatch({
        type: ADD_CLIENT_REQ_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const AddNewCOmpany = (payload: any, func: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: ADD_NEW_COMPANY,
      });

      const {data} = await jwtAxios.post(
        `accounts/company-registration/`,
        payload,
      );

      dispatch({
        type: ADD_NEW_COMPANY_SUCCESS,
        payload: data,
      });

      if (data?.success === true) {
        dispatch(showMessage(data?.result));
        let apiBody = {
          offset: 0,
          userId: payload?.user_id,
          query: '',
        };

        setTimeout(() => {
          func();
          dispatch(GetCompanyListNew(apiBody));
        }, 2000);
      } else {
        dispatch(fetchError(data?.result));
      }
    } catch (error) {
      dispatch({
        type: ADD_NEW_COMPANY_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const UpdateCompany = (payload: any, func?: any, userId?: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: UPDATE_COMPANY_DETAILS,
      });

      const {data} = await jwtAxios.put(
        `accounts/company-admin-update/`,
        payload,
      );

      dispatch({
        type: UPDATE_COMPANY_DETAILS_SUCCESS,
        payload: data,
      });

      if (data?.success === true) {
        dispatch(showMessage('Company updated successfully !'));

        if (func) {
        } else {
          localStorage.removeItem('REGISTRATION_BODY');
        }
      } else {
        dispatch(fetchError(data?.result));
      }
    } catch (error) {
      dispatch({
        type: UPDATE_COMPANY_DETAILS_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const AddBeeUser = (payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: ADD_BEE_REQ,
      });

      const {data} = await jwtAxios.post(`accounts/create-bee-user/`, payload);

      dispatch({
        type: ADD_BEE_REQ_SUCCESS,
        payload: data,
      });

      if (data?.result?.success === 'true') {
        dispatch(showMessage(data?.result?.result));
      } else {
        dispatch(fetchError(data?.result?.result));
      }
    } catch (error) {
      dispatch({
        type: ADD_BEE_REQ_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const PostbackendCountryReports = (payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: POST_COUNTRY_REPORTS,
      });

      const {data} = await jwtAxios.post(
        `dashboard/backend/country-reports/`,
        payload,
      );

      dispatch({
        type: POST_COUNTRY_REPORTS_SUCCESS,
        payload: data,
      });
      dispatch(showMessage('Country Report  Added'));
      downloadFile(data, `country-${payload.country == 1 ? 'UK' : 'USA'}`);
    } catch (error) {
      dispatch({
        type: POST_COUNTRY_REPORTS_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const PostbackendManagementReports = (payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: POST_MANAGEMENT_REPORTS,
      });

      const {data} = await jwtAxios.post(
        `dashboard/backend/management-reports/`,
        payload,
      );

      dispatch({
        type: POST_MANAGEMENT_REPORTS_SUCCESS,
        payload: data,
      });

      downloadFile(data, `management-${payload.country == 1 ? 'UK' : 'USA'}`);
    } catch (error) {
      dispatch({
        type: POST_MANAGEMENT_REPORTS_ERROR,
      });
      dispatch(fetchError());
    }
  };
};
export const PostbackendReferrals = (payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: POST_REFERRAL_REPORT,
      });

      const {data} = await jwtAxios.post(
        `dashboard/backend/referral-reports/`,
        payload,
      );

      dispatch({
        type: POST_REFERRAL_REPORT_SUCCESS,
        payload: data,
      });

      downloadFile(data, `referral-${payload.country == 1 ? 'UK' : 'USA'}`);
    } catch (error) {
      dispatch({
        type: POST_REFERRAL_REPORT_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetMoviesList = (movieList: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_MOVIES_LIST,
      });

      if (movieList?.querry) {
        var {data} = await jwtAxios.get(
          `dashboard/backend/movies-list/?offset=${movieList?.offset}&user_id=${movieList?.userId}&query=${movieList?.querry} `,
        );
      } else {
        var {data} = await jwtAxios.get(
          `dashboard/backend/movies-list/?offset=${movieList?.offset}&user_id=${movieList?.userId}&status=${movieList?.status}&company=${movieList?.company}&start=${movieList?.start}&end=${movieList?.end}`,
        );
      }

      dispatch({
        type: GET_MOVIES_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_MOVIES_LIST_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetMovieDetail = (Payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_COMPANY_DETAIL,
      });

      const {data} = await jwtAxios.get(
        `dashboard/backend/movies/movie-detail/?user_id=${Payload?.user_id}&movie_key=${Payload.movie_key}`,
      );

      dispatch({
        type: GET_COMPANY_DETAIL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_COMPANY_DETAIL_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetTranscodeDetail = (Payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_TRANSCODE_MOVIE,
      });

      const {data} = await jwtAxios.get(
        `dashboard/backend/movies/transcode/?user_id=${Payload?.user_id}&movie_key=${Payload?.key_id}`,
      );

      dispatch({
        type: GET_TRANSCODE_MOVIE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_TRANSCODE_MOVIE_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetAnalyticsDetails = (Payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_ANALYTICS_DETAILS,
      });

      const {data} = await jwtAxios.get(
        `dashboard/backend/movies/analytics/?user_id=${Payload?.user_id}&movie_key=${Payload?.key_id}`,
      );

      dispatch({
        type: GET_ANALYTICS_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_ANALYTICS_DETAILS_ERROR,
      });
      dispatch(fetchError());
    }
  };
};
export const GetBeesListAction = (BeesList: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_BEES_LIST_NEW,
      });

      const {data} = await jwtAxios.get(
        `dashboard/backend/bees-list/?offset=${BeesList?.offset}&user_id=${BeesList?.userId}&query=`,
      );
      dispatch({
        type: GET_BEES_LIST_NEW_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_BEES_LIST_NEW_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetBeesListActionSearch = (BeesList: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_BEES_LIST_NEW,
      });

      const {data} = await jwtAxios.get(
        `dashboard/backend/bees-list/?offset=${BeesList?.offset}&user_id=${BeesList?.userId}&query=${BeesList?.query}`,
      );
      dispatch({
        type: GET_BEES_LIST_NEW_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_BEES_LIST_NEW_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetPaymentList = (offset: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_PAYMENT_LIST,
      });

      const {data} = await jwtAxios.get(
        `stripe/one-time-payments/?offset=${offset}`,
      );
      dispatch({
        type: GET_PAYMENT_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_PAYMENT_LIST_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const AddNewPaymentLink = (Payload: any,close:any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: POST_NEW_PAYMENTLINK,
      });

      const {data} = await jwtAxios.post(
        `stripe/onetime-payment-link-generation/`,
        Payload,
      );
      dispatch({
        type: POST_NEW_PAYMENTLINK_SUCCESS,
        payload: data,
      });
      dispatch(showMessage('One Time Payment Link Generated'));
      setTimeout(() => {
        dispatch(GetPaymentList(0));
        dispatch(hideMessage());
        close()
      }, 2000);
    } catch (error) {
      dispatch({
        type: POST_NEW_PAYMENTLINK_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const UpdatePassword = (Payload: any, close: () => void) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: POST_NEW_PASSWORD,
      });

      const {data} = await jwtAxios.post(`accounts/reset-password/`, Payload);
      dispatch({
        type: POST_NEW_PASSWORD_SUCCESS,
        payload: data,
      });
      dispatch(showMessage(data.result));
      setTimeout(() => {
        close();
        dispatch(hideMessage());
      }, 2000);
    } catch (error) {
      dispatch({
        type: POST_NEW_PASSWORD_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const AddFeedback = (Payload: any, close: () => void) => {
  return async (dispatch: any) => {
    try {
      const {data} = await jwtAxios.post(`dashboard/feedback-email/`, Payload);

      dispatch(showMessage(data.result));
      setTimeout(() => {
        close();
        dispatch(hideMessage());
      }, 2000);
    } catch (error) {
      dispatch(fetchError());
    }
  };
};

export const PostNewCompany = (postNewCompanyPayload: any, func: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: POST_COMPANY_LIST,
      });

      const {data} = await jwtAxios.post(
        `dashboard/backend/add-company/`,
        postNewCompanyPayload,
      );

      dispatch({
        type: POST_COMPANY_LIST_SUCCESS,
        payload: data.result,
      });
      dispatch(showMessage('One Time Payment Link Generated'));
      let apiBody = {
        offset: 0,
        userId: postNewCompanyPayload?.user_id,
        query: '',
      };
      func();
      dispatch(GetCompanyListNew(apiBody));
    } catch (error) {
      dispatch({
        type: POST_COMPANY_LIST_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const ChangeUser = (payload: any, func: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: PUT_CLIENT_USER,
      });

      const {data} = await jwtAxios.post(`orders/order-user-update/`, payload);

      dispatch({
        type: PUT_CLIENT_USER_SUCCESS,
        payload: data.result,
      });
      dispatch(showMessage(data.result));

      setTimeout(func());
    } catch (error) {
      dispatch({
        type: PUT_CLIENT_USER_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const PostpondCall = (postNewCompanyPayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: POSTPOND_CALL,
      });

      const {data} = await jwtAxios.post(
        `scheduler/asset-approval-actions/`,
        postNewCompanyPayload,
      );

      dispatch({
        type: POSTPOND_CALL_SUCCESS,
        payload: data.result,
      });
      dispatch(showMessage(data.result));
    } catch (error) {
      dispatch({
        type: POSTPOND_CALL_ERROR,
      });
      dispatch(fetchError());
    }
  };
};
export const BeeNotesall = (postNewCompanyPayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: BEE_NOTES,
      });

      const {data} = await jwtAxios.post(
        `scheduler/asset-approval-actions/`,
        postNewCompanyPayload,
      );

      dispatch({
        type: BEE_NOTES_SUCCESS,
        payload: data.result,
      });
      dispatch(showMessage(data.result));
    } catch (error) {
      dispatch({
        type: BEE_NOTES_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const postTaskSupportNotes = (postTaskSupportNotesPayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: SUPPORT_NOTES,
      });

      const {data} = await jwtAxios.post(
        `orders/support-notes/`,
        postTaskSupportNotesPayload,
      );

      dispatch({
        type: SUPPORT_NOTES_SUCCESS,
        payload: data.result,
      });
      dispatch(showMessage(data.result));
    } catch (error) {
      dispatch({
        type: SUPPORT_NOTES_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const postCompleteTask = (postCompleteTaskPayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: POST_COMPLETE_TASK,
      });

      const {data} = await jwtAxios.post(
        `dashboard/complete-client-conform-task/`,
        postCompleteTaskPayload,
      );

      dispatch({
        type: POST_COMPLETE_TASK_SUCCESS,
        payload: data.result,
      });
      dispatch(showMessage(data.result));
    } catch (error) {
      dispatch({
        type: POST_COMPLETE_TASK_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const postFeildChangetask = (postFeildChangetaskPayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: POST_FEILD_CHANGE_TASK,
      });

      const {data} = await jwtAxios.post(
        `scheduler/field-change-actions/`,
        postFeildChangetaskPayload,
      );

      dispatch({
        type: POST_FEILD_CHANGE_TASK_SUCCESS,
        payload: data.result,
      });
      dispatch(showMessage(data.result));
    } catch (error) {
      dispatch({
        type: POST_FEILD_CHANGE_TASK_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const postClientFollowUpTask = (postClientFollowUpTaskPayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: POST_CLIENT_TASK,
      });

      const {data} = await jwtAxios.post(
        `scheduler/client-followup-actions/`,
        postClientFollowUpTaskPayload,
      );

      dispatch({
        type: POST_CLIENT_TASK_SUCCESS,
        payload: data.result,
      });
      dispatch(showMessage(data.result));
    } catch (error) {
      dispatch({
        type: POST_CLIENT_TASK_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const postClientCompanyDetails = (postClientCompanyDetails: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: POST_CLIENT_COMPANY_DETAILS,
      });

      const {data} = await jwtAxios.post(
        `dashboard/client-company-details/`,
        postClientCompanyDetails,
      );

      dispatch({
        type: POST_CLIENT_COMPANY_DETAILS_SUCCESS,
        payload: data.result,
      });
      dispatch(showMessage(data.result));
    } catch (error) {
      dispatch({
        type: POST_CLIENT_COMPANY_DETAILS_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const updateBeeUser = (PutBeeDetailPayload: any, redirect: string) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: PUT_BEE_UPDATE,
      });

      const {data} = await jwtAxios.put(
        `accounts/update-bee-user/`,
        PutBeeDetailPayload,
      );

      dispatch({
        type: PUT_BEE_UPDATE_SUCCESS,
        payload: data.result,
      });

      if (data?.result?.success === 'true') {
        if (redirect == 'no-redirect') {
          dispatch(showMessage('Account details updated !'));
        } else {
          dispatch(showMessage('Bee Added !'));
          setTimeout(() => {
            window.location.replace(
              '/dashboard/backend/report/management/bees/',
            );
          }, 2000);
        }
      } else {
        dispatch(showMessage(data?.result));
      }
    } catch (error) {
      dispatch({
        type: PUT_BEE_UPDATE_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetRenewTask = (Payload: any) => {
  return async (dispatch: any) => {
    try {
      const res = await jwtAxios.get(
        `other/renew-hosting-api/?reference=${Payload.reference}&addon_id=${Payload.addon_id}&amount=${Payload?.amount}&addon=${Payload?.addon}`,
      );

      if (res.statusText === 'OK') {
        let url = res?.data[0]?.url;
        setTimeout(() => {
          window.location.replace(url);
        }, 500);
      }
    } catch (error) {
      dispatch(fetchError('something went wrong!'));
    }
  };
};

export const createUnavailableSlot = (createUnavailableSlot: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: POST_UNAVAILABLE_SLOT,
      });

      const {data} = await jwtAxios.post(
        createUnavailableSlot.slot
          ? `scheduler/create-unavailable-slot/`
          : `scheduler/create-unavailable-day/`,
        createUnavailableSlot,
      );

      dispatch({
        type: POST_UNAVAILABLE_SLOT_SUCCESS,
        payload: data.result,
      });
      dispatch(showMessage('Availability Updated'));
    } catch (error) {
      dispatch({
        type: POST_UNAVAILABLE_SLOT_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const deleteUnavailableSlot = (deleteUnavailableSlotPayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: DEL_UNAVAILABLE_SLOT,
      });

      const response = await jwtAxios.delete(`scheduler/destroy-unvailable/`, {
        data: deleteUnavailableSlotPayload,
        headers: {
          'Content-Type': 'application/json',
        },
      });

      dispatch({
        type: DEL_UNAVAILABLE_SLOT_SUCCESS,
        payload: response.data.result,
      });
      dispatch(showMessage(response.data.result));
    } catch (error) {
      dispatch({
        type: DEL_UNAVAILABLE_SLOT_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const PostReShootDetails = (Payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: POST_RESHOOT_DETAILS,
      });

      const {data} = await jwtAxios.post(`/dashboard/order-reshoot/`, Payload);

      dispatch({
        type: POST_RESHOOT_DETAILS_SUCCESS,
        payload: data.result,
      });
      dispatch(showMessage(data.result));
      setTimeout(() => {
        window.location.replace(`/dashboard/orders/detail/${Payload?.ref}`);
      }, 2000);
    } catch (error) {
      dispatch({
        type: POST_RESHOOT_DETAILS_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetUnavailableList = (Payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_UNAVAILABLE_LIST,
      });

      const {data} = await jwtAxios.get(
        `dashboard/unavailable-list/?start=${Payload?.start_date}&end=${Payload?.end_date}&user_id=${Payload?.user_id}`,
      );

      dispatch({
        type: GET_UNAVAILABLE_LIST_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: GET_UNAVAILABLE_LIST_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetAvailableList = (Payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_AVAILABLE_LIST,
      });

      const {data} = await jwtAxios.get(
        `dashboard/beetask-list/?start=${Payload.start_date}&end=${Payload?.end_date}&user_id=${Payload?.user_id}`,
      );

      dispatch({
        type: GET_AVAILABLE_LIST_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: GET_AVAILABLE_LIST_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const GetAllShooterList = (Payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_ALL_SHOOTERLIST,
      });

      const {data} = await jwtAxios.get(
        `dashboard/backend/all-bees-list/?&user_id=${Payload?.user_id}`,
      );

      dispatch({
        type: GET_ALL_SHOOTERLIST_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_SHOOTERLIST_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const PostAmendPhoto = (Payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: POST_AMEND_PHOTO,
      });

      const {data} = await jwtAxios.post(`orders/photo-amend/`, Payload);

      dispatch({
        type: POST_AMEND_PHOTO_SUCCESS,
        payload: data.result,
      });
      dispatch(showMessage('Amend Successfully'));
    } catch (error) {
      dispatch({
        type: POST_AMEND_PHOTO_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const PostAmendBroucher = (Payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: POST_AMEND_BROUCHER,
      });

      const {data} = await jwtAxios.post(`orders/brochure-amend/`, Payload);

      dispatch({
        type: POST_AMEND_BROUCHER_SUCCESS,
        payload: data.result,
      });
      dispatch(showMessage('Amend Successfully'));
    } catch (error) {
      dispatch({
        type: POST_AMEND_BROUCHER_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

export const PostAmendFloorplan = (Payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: POST_AMEND_FLOORPLAN,
      });

      const {data} = await jwtAxios.post(`orders/floor-plan-amend/`, Payload);

      dispatch({
        type: POST_AMEND_FLOORPLAN_SUCCESS,
        payload: data.result,
      });
      dispatch(showMessage('Amend Successfully'));
    } catch (error) {
      dispatch({
        type: POST_AMEND_FLOORPLAN_ERROR,
      });
      dispatch(fetchError());
    }
  };
};


export const GetInvoiceEditDetails = (Payload: {
  id: string | number;
   
}) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_INVOICE_EDIT_DETAIL,
      });

      const {data} = await jwtAxios.get(
        `dashboard/bee-invoice-entry-details/?entry_id=${Payload.id}`,
      );

      dispatch({
        type: GET_INVOICE_EDIT_DETAIL_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: GET_INVOICE_EDIT_DETAIL_ERROR,
      });
      dispatch(fetchError());
    } //GET_INVOICE_EDIT_DETAIL,GET_INVOICE_EDIT_DETAIL_SUCCESS,GET_INVOICE_EDIT_DETAIL_ERROR
  };
};

export const GetMSLDetails = (Payload: {
  key: string | number;
   
}) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: GET_MSL_DETAILS,
      });

      const {data} = await jwtAxios.get(
        `orders/order-assets/mls-link/?key=${Payload.key}`,
      );

      dispatch({
        type: GET_MSL_DETAILS_SUCCESS,
        payload: data.result,
      });
    } catch (error) {
      dispatch({
        type: GET_MSL_DETAILS_ERROR,
      });
      dispatch(fetchError());
    } //GET_MSL_DETAILS,GET_MSL_DETAILS_SUCCESS,GET_MSL_DETAILS_ERROR
  };
};

export const RenewHoisting = (payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: RENEW_HOISTING,
      });

      const {data} = await jwtAxios.put(
        `orders/order-management/renew-hosting/`,
        payload,
      );

      dispatch({
        type: RENEW_HOISTING_SUCCESS,
        payload: data.result,
      });

      dispatch(showMessage(data?.result));
      setTimeout(() => {
       window.location.reload()
      }, 2000);
    } catch (error) {
      dispatch({
        type: RENEW_HOISTING_ERROR,
      });
      dispatch(fetchError());
    } //RENEW_HOISTING,RENEW_HOISTING_SUCCESS,RENEW_HOISTING_ERROR
  };
};

export const CancelHoisting = (payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: CANCEL_HOISTING,
      });

      const {data} = await jwtAxios.put(
        `orders/order-management/cancel-hosting/`,
        payload,
      );

      dispatch({
        type: CANCEL_HOISTING_SUCCESS,
        payload: data.result,
      });

      dispatch(showMessage(data?.result));
      
      setTimeout(() => {
       window.location.reload()
      }, 2000);
    } catch (error) {
      dispatch({
        type: CANCEL_HOISTING_ERROR,
      });
      dispatch(fetchError());
    } //CANCEL_HOISTING,CANCEL_HOISTING_SUCCESS,CANCEL_HOISTING_ERROR
  };
};

export const showBulkUploadErrorMessage=(errorMsg:any)=>{
  return async (dispatch: any) => {
    dispatch(showWarning(errorMsg));
  }

}