import React from 'react';
//import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import './FooterLatest.css'; // Import the custom CSS for the footer

const AppFooterLatest = ({
    bookNowUrl,
    faqUrl,
    bookingHelpUrl,
    bookUrl,
    privacyPolicyUrl,
    termsConditionsUrl,
    cookiesUrl,
    socialLinks,
}:any) => {
    return (
       
            <div  style={{ backgroundColor: '#003C32' }} id="footer">
                <div className="container-fluid text-light footer wow fadeIn" data-wow-delay="0.1s">
                    <div className="container-xxl py-5">
                        <div className="row g-5">
                            <div className="col-lg-2 col-md-6 footer-nav">
                                <h4 className="text-light mb-4">Help</h4>
                                {bookNowUrl && (
                                    <a href={bookNowUrl} target="_blank" className="btn btn-link" style={{ fontSize: '16px' }}>
                                        Book Now
                                    </a>
                                )}
                                <a className="btn btn-link" href="https://vistabee.com/vistabee-faq/">FAQ</a>
                                {/* {bookingHelpUrl && <a className="btn btn-link" target="_blank" href={bookingHelpUrl}>Help With Booking</a>}
                                {bookUrl && <a className="btn btn-link" target="_blank" href={bookUrl}>Book</a>} */}
                            </div>
                            <div className="col-lg-2 col-md-6 footer-nav">
                                <h4 className="text-light mb-4">About</h4>
                                <a className="btn btn-link" href="https://vistabee.com/about">About Us</a>
                                <a className="btn btn-link" href="https://vistabee.com/service">Services</a>
                                <a className="btn btn-link" href="https://vistabee.com/portfolio">Portfolio</a>
                            </div>
                            <div className="col-lg-2 col-md-6 footer-nav">
                                <h4 className="text-light mb-4">Info</h4>
                                <a className="btn btn-link" href="https://vistabee.com/contact-us">Contact Us</a>
                            </div>
                        </div>
                    </div>
                    <div className="container-xxl">
                        <div className="copyright">
                            <img  src='/assets/images/latestIcons/hex_logo.png' className="logo-gap" alt="Logo" />
                            <div className="row footer-bottom-header">
                                <div className="col-md-6 text-left text-md-start mb-3 mb-md-0">
                                    <a className="margin-right" href="/">© vistaBee 2024</a>
                                    <a className="margin-right" href="https://vistabee.com/privacy-policy/">Privacy Policy</a>
                                   <a className="margin-right" href="https://vistabee.com/terms-and-conditions/">Terms & Conditions</a>
                                   <a href="/vistabee-cookies/" className='margin-right'>Cookies</a>
                                </div>
                                <div className="col-md-3" style={{ display: 'flex', gap: '20px', paddingLeft: '10px', justifyContent: 'space-between' }}>
                                    {socialLinks.map((link:any, index:any) => (
                                        <a key={index} target="_blank" href={link.url} className="ml-3">
                                            <img src={link.icon} alt={`social-${index}`} />
                                        </a>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
    );
};

export default AppFooterLatest;