import moment from 'moment';
import {INewOrderPayload} from './INewOrderPayload';

const getArrayIds = (payload: INewOrderPayload) => {
  let arrayAddons: number[] = [];

  if (
    payload?.addonsDetails?.addons?.addonArray &&
    payload?.addonsDetails?.addons?.addonArray.length > 0
  ) {
    payload?.addonsDetails?.addons?.addonArray.forEach((values: any) => {
      arrayAddons.push(values?.id);
    });
  }
  if (
    payload?.recommendedProductDetails?.addons &&
    payload?.recommendedProductDetails?.addons.length > 0
  ) {
    payload?.recommendedProductDetails?.addons.forEach((values: any) => {
      arrayAddons.push(values?.id);
    });
  }

  return arrayAddons;
};
const getCheckValue = (value: any) => {
  let Temp = {...value};
  delete Temp.label;
  delete Temp?.isNoSpecialRequirement;
  const isEmpty = Object.values(Temp).every((x) => x === '');

  return !isEmpty;
};

const getPlaceOrderApiPayload = (payload: INewOrderPayload, appliedPoints ?: any) => {
  const apiBody = {
    user_id: payload?.clientDetails?.userId,
    product_id:
      payload?.addonsDetails?.addons?.productId ??
      payload?.recommendedProductDetails?.productId,
    sub_category_id: payload?.addonsDetails?.subCategory,
    package_id:
      payload?.recommendedProductDetails?.packageId ??
      payload?.addonsDetails?.addons?.packageId,

    order_total_price: appliedPoints ? (payload?.recommendedProductDetails?.price - appliedPoints).toFixed(2) : payload?.recommendedProductDetails?.price,
    active_credits_used: appliedPoints ? appliedPoints : 0,
    addons_ids: getArrayIds(payload),

    location_detail: {
      shoot_country:
        payload?.locationAndTimeDetails?.country === '1'
          ? 'United Kingdom'
          : 'Unites States of America',
      post_code: payload?.locationAndTimeDetails?.postcode,
      shoot_address: payload?.locationAndTimeDetails?.addressLine1,
      shoot_address2: payload?.locationAndTimeDetails?.addressLine2,
      shoot_city: payload?.locationAndTimeDetails?.city,
      shoot_street_no: '',
    },
    contact_me_to_arrange_a_date_time: {
      is_enable:
        payload?.locationAndTimeDetails?.contactMe,
      date: payload?.locationAndTimeDetails.contactMeDate
        ? moment(payload?.locationAndTimeDetails.contactMeDate).format(
            'YYYY-MM-DD',
          )
        : null,
      time: payload?.locationAndTimeDetails.contactMeTime,
    },
    key_pick_up_locations: payload.key_pick_up_locations,
    key_pick_up_type: payload.key_pick_up_type,
    contact_details: {
      first_name: payload?.contactDetails?.firstName,
      last_name: payload?.contactDetails?.lastName,
      phone: payload?.contactDetails?.telephone,
      mobile: payload?.contactDetails?.mobile,
      email: payload?.contactDetails?.email,
      external_reference: payload?.Reference?.label,
      contact_person_user_type: 'other',
    },

    special_request: {
      is_special_request_enable: getCheckValue(payload?.Reference),
      ...payload?.Reference?.requests,
    },
    reference: null,
  };

  if (payload.recommendedProductDetails?.companyPackageId) {
    apiBody.company_package_id =
      payload?.recommendedProductDetails?.companyPackageId;
  }

  if (payload.recommendedProductDetails?.recommendedId) {
    apiBody.recommended_package_id =
      payload?.recommendedProductDetails?.recommendedId;
  }

  delete apiBody.key_pick_up_locations.type;
  return apiBody;
};

export const getAddonsTotalPrice = (addonsDetails: any) => {
  let totalPrice: number = 0;
  addonsDetails?.addons?.addonArray?.forEach((element: any) => {
    totalPrice += element?.gross_price ?? 0;
  });
  return totalPrice;
};

export default getPlaceOrderApiPayload;
